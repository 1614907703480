import './LayoutPage.css'
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next'
import HeaderStartPage from "../startPage/header/HeaderStartPage";
import {LangProp, pathItem} from "../startPage/helpers";
import LeftLayoutMenu from "./leftLayoutMenu/LeftLayoutMenu";
import GridLayout from "./gridLayout/GridLayout";
import BottomPanel from "../bottom/BottomPanel";
import {Sidebar} from "nx-design/Sidebar";
import ViewContainerUpgrade from "../designer/container/ViewContainerUpgrade";
import RightSideBarLite from "./rightSideBar/RightSideBarLite";
import {typeSidebarProp, TypeSidebarProp} from "../sideBar/SideBar";
import ApplicationInfo from "../views/ApplicationInfo";

export type PropLayoutPage = {
    setLang: (lang: LangProp) => void;
    pushHref: (href: string) => void;
    onSelectObject: (arg: object) => void;
    pathHistory: pathItem[][];
    push: (path: string) => void;
    path: any[];
    activeObject: pathItem;
    context: any;
};

function LayoutPage (props: PropLayoutPage) {

    const {
        setLang,
        pushHref,
        onSelectObject,
        pathHistory,
        push,
        path,
        activeObject,
        context
    } = props;

    const { t } = useTranslation();

    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
    const [openObject, setOpenObject] = useState<pathItem>();
    const [typeSidebar, setTypeSidebar] = useState<TypeSidebarProp>(typeSidebarProp[0]);

    let container = document.getElementById("layout-page-right-grid");
    let sidebar = document.getElementsByClassName("sidebar-bottom")[0];
    container !== null && sidebar !== null && container.appendChild(sidebar);

    useEffect(() => {
        if (path.length <= 3 && context.openDesigner) {
            context.updateContext({ openDesigner: false })
        }
        if (path[3] && path[3].name !== "" && (path[3]._type_ === "etl.Transformation" || path[3]._type_ === "etl.Workflow" || path[3]._type_ === "rt.LivyServer" || path[3]._type_ === "rt.Oozie")) {
            context.updateContext({ openDesigner: true })
        }
        if (path[3] && path[3]._type_ !== "etl.Transformation" && path[3]._type_ === "etl.Workflow" && path[3]._type_ === "rt.LivyServer" && path[3]._type_ === "rt.Oozie") {
            context.updateContext({ openDesigner: false })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[path]);

    useEffect(() => {
        if (path.length === 2) {
            document.title = path[1].name + ' - Datagram';
        }
        if (path.length === 3) {
            document.title = path[2].name.split('.')[1] + ' - Datagram';
        }
        if (path.length === 4) {
            document.title = path[3].name + ' - Datagram';
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[path]);

    return (
        <div className='layoutPage' id={'layoutPage'}>
            <div id={'bar'}>
                <LeftLayoutMenu
                    path={path}
                    activeObject={activeObject}
                    push={push}
                    pathHistory={pathHistory}
                    context={context}
                    openRightSideBarLite={(isSidebarOpen_, openObject_, typeSidebar_) => {
                        setIsSidebarOpen(isSidebarOpen_)
                        setOpenObject(openObject_)
                        setTypeSidebar(typeSidebar_)
                    }}
                />
                {isSidebarOpen && openObject &&
                    <RightSideBarLite
                        activeObject={activeObject}
                        openObject={openObject}
                        onCloseSideBar={(clickSave: boolean) => setIsSidebarOpen(false)}
                        stateSidebar={isSidebarOpen}
                        typeSidebar={typeSidebar}
                        context={context}
                    />
                }
            </div>
            <div className={'layout-page-right-grid'} id={'layout-page-right-grid'}>
                <div className={'header-content-container'}>
                    <HeaderStartPage
                        setLang={setLang}
                        pushHref={pushHref}
                        onSelectObject={onSelectObject}
                        pathHistory={pathHistory}
                        push={push}
                        layoutPage
                        path={path}
                        context={context}
                    />
                    {path[3] && context.openDesigner && ( path[3]._type_ === "etl.Transformation" || path[3]._type_ === "etl.Workflow" || path[3]._type_ === "rt.LivyServer" || path[3]._type_ === "rt.Oozie" ) ?
                        <ViewContainerUpgrade
                            onSelectObject={onSelectObject}
                            activeObject={activeObject}
                            context={context}
                            t={t}
                            urlParams={new URLSearchParams(window.location.search)}
                            addBottomItem={()=>console.log('addBottomItem')}
                            removeBottomItem={()=>console.log('removeBottomItem')}
                            updateItemProps={()=>console.log('updateItemProps')}
                        />
                        : path[1] && path[1].name === "ApplicationInfo" ?
                            <ApplicationInfo
                                t={t}

                            />
                        :
                        <GridLayout
                            push={push}
                            path={path}
                            activeObject={activeObject}
                            context={context}
                            onSelectObject={onSelectObject}
                            isSidebarOpenOther={isSidebarOpen}
                            closeOther={() => setIsSidebarOpen(false)}
                        />
                    }
                </div>
                <Sidebar
                    isOpen={true}
                    position={"bottom"}
                    hasOverlay={false}
                    includeSpace={true}
                    className={'sidebar-bottom'}
                    width={'auto'}
                >
                    <Sidebar.Content>
                        <BottomPanel
                            activeObject={activeObject}
                            context={context}
                            path={path}
                        />
                    </Sidebar.Content>
                </Sidebar>
            </div>
         </div>

    );

}

export default withTranslation()(LayoutPage);
