import './Play.css'
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next'
import resource from "../../../Resource";
import {Button} from "nx-design/Button";
import {pathItem} from "../../startPage/helpers";
import _ from "lodash";
import {buttonAddPropType, LeftSide, Table} from "nx-design/Table";
import {IconSettingEdit} from "nx-design/IconSettingEdit";
import {IconCopy} from "nx-design/IconCopy";
import {IconDelete} from "nx-design/IconDelete";
import {HeaderSideProps} from "nx-design/__internal__/cjs-src/components/Table/headerMenu";
import SideBar, {TypeSidebarProp, typeSidebarProp, typeSidebarPropDefault} from "../../sideBar/SideBar";
import update from "immutability-helper";
import {TextField} from "nx-design/TextField";
import {Select} from "nx-design/Select";

export type PropPlay = {
    activeObject: pathItem;
    setError: () => void;
    setDeployment: (value: boolean) => void;
    playTransformation: boolean;
    setPlay: () => void;
    variablesVisible: boolean;
    context: any;
};

function Play (props: PropPlay) {

    const {
        activeObject,
        setError,
        setDeployment,
        playTransformation,
        setPlay,
        variablesVisible,
        context
    } = props;

    const [deploymentList, setDeploymentList] = useState<any>([]);
    const [selectedDeployment, setSelectedDeployment] = useState<any>(undefined);
    const [data, setData] = useState<any[]>([]);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const [typeSidebar, setTypeSidebar] = useState<TypeSidebarProp>(typeSidebarPropDefault);
    const [isLineVisibleTable, setLineVisibleTable] = useState<boolean>(false);
    const [newItem, setNewItem] = useState<any>(undefined);
    const { t } = useTranslation();

    useEffect(() => {
        if (newItem === undefined) {
            resource.call(activeObject, 'checkTRD').then(json =>
            {if (json.result) {getDeploymentList(false)}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[newItem]);

    useEffect(() => {
        if (activeObject && newItem !== undefined && activeObject._type_ !== undefined && activeObject._type_ === "etl.Transformation" && activeObject.name !== "etl.Transformation") {
            resource.call(activeObject, 'checkTRD').then(json =>
            {if (json.result) {getDeploymentList(true)}})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeObject]);

    useEffect(() => {
        playTransformation && runTransformation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[playTransformation]);

    const columns = [
        {
            title: t('Name'),
            accessor: 'name',
        },
        {
            title: t('Value'),
            accessor: 'value',
        },
        {
            title: t('Description'),
            accessor: 'description',
        },
        {
            title: 'id',
            accessor: 'id',
            hidden: true
        },
        {
            title: t('Actions'),
            accessor: 'actions',
            width: 115,
            renderCell: (row: any) => {
                return (
                    <div className={'actions-column'}>
                        <Button
                            id={JSON.stringify({id: row.id, name: row.name, _type_: row.e_type})}
                            name={row.name}
                            size="xs"
                            view="clear"
                            iconLeft={IconSettingEdit}
                            onlyIcon
                            onClick={(e: any) => {onEdit(e)}}
                        />
                        <Button
                            id={JSON.stringify({id: row.id, name: row.name, _type_: row.e_type})}
                            name={row.name}
                            size="xs"
                            view="clear"
                            iconLeft={IconCopy}
                            onlyIcon
                            onClick={(e: any) => {onCopy(e)}}
                        />
                        <Button
                            id={JSON.stringify({id: row.id, name: row.name, deploymentName: row.deploymentName})}
                            name={row.name}
                            size="xs"
                            view="clear"
                            iconLeft={IconDelete}
                            onlyIcon
                            onClick={(e: any) => {onDelete(e)}}
                        />
                    </div>
                );
            }
        },
        {
            title: 'DeploymentName',
            accessor: 'deploymentName',
            hidden: true
        },
        ]

    const getDeploymentList = (updateSelectedDeployment: boolean) => {
        resource.query("/api/teneo/select/from%20rt.TransformationDeployment%20where%20transformation.e_id=" + activeObject.e_id).then(json => {
            if (json.length === 0) {setDeployment(true)}
            else {setDeployment(false)}
            context.updateContext({ updateLeftLayoutMenu: true })

            const data = json.length > 0 ?
                json.map((d: any) => d.parameters.map(
                    (p: any, index: number) => ({ name: p.name, value: p.value, description: p.description ? p.description : null, id: index, deploymentName: d.name })
                )) : []
            setData(_.flatten(data))
            setDeploymentList(json)
            if (selectedDeployment === undefined || updateSelectedDeployment) {
                setSelectedDeployment({
                    label: json.length > 0 ? json[0].name : null,
                    id: 0
                })
            }
            setLineVisibleTable(false)
        })
    }

    const runTransformation = () => {
        if (selectedDeployment) {
            const trd = deploymentList.find((d: any) => d.name === selectedDeployment.label)
            const params: any = {}
            data.forEach((p: any) => params[p.name] = p.value)
            resource.call({ ...activeObject, trd, params }, 'runit').then(json =>
                json.result ?
                    resource.logInfo('Successful run.')
                    :
                    json.problems ? json.problems.map((p: any) => resource.logError(<div> {p.constraint} <br /> {p.context} <br /> {p.message} <br /></div>)) : 'Undefined error.'

            ).catch(() => setError())
            setPlay()
        }
    }

    const getDataSource = () => {
        return selectedDeployment ? data.filter( parameter => parameter.deploymentName === selectedDeployment.label ) : []
    }

    const onClickButtonAddColumn = (event: any) => {
        setIsSidebarOpen(true);
        setTypeSidebar(typeSidebarProp[1]);
    }

    const leftSide: HeaderSideProps =
        {
            name: LeftSide,
            props: {
                nameButtonAddColumn: t('new'),
                nameButtonRefresh: t('refresh'),
                onClickButtonRefresh: () => {onClickButtonRefresh()},
                buttonAddType: buttonAddPropType[0],
                onClickButtonAdd: (event: any) => {onClickButtonAddColumn(event)},
                buttonAddColumnVisible: true
            }
        };

    const onClickButtonRefresh = () => {
        setLineVisibleTable(true)
        getDeploymentList(false)
    };

    const onClose = () => {
        setIsSidebarOpen(false);
    };

    const onSave = (onCopyItem: any = undefined) => {
        const updateItem: any = onCopyItem || newItem;
        setIsSidebarOpen(false);
        if (selectedDeployment && updateItem !== undefined) {
            const trd = deploymentList.find((d: any) => d.name === selectedDeployment.label)
            let newParams: any[] = []
            const oldParams = data.filter(parameter => parameter.deploymentName === selectedDeployment.label)
            if (oldParams) {newParams = oldParams.map((p: any) => ({_type_: 'etl.Property', name: p.name, value: p.value, t_id: trd.e_id, description: p.description}))}
            if (updateItem.id !== undefined) {newParams.splice(updateItem.id, 1)}
            newParams.push({_type_: 'etl.Property', name: updateItem.name, value: updateItem.value, t_id: trd.e_id, description: updateItem.description})
            const updatedTrd = update(trd, { $merge: { parameters: newParams } })
            setNewItem(undefined)
            resource.saveEntity(updatedTrd).then(value => onClickButtonRefresh())
        }
    };

    const onDelete = (e: any) => {
        const delEntity = JSON.parse(e.currentTarget.id);
        if (selectedDeployment) {
            const trd = deploymentList.find((d: any) => d.name === selectedDeployment.label)
            let oldParams = data.filter(parameter => parameter.deploymentName === selectedDeployment.label)
            oldParams.splice(delEntity.id, 1)
            let newParams: any[] = []
            if (oldParams) {newParams = oldParams.map((p: any) => ({_type_: 'etl.Property', name: p.name, value: p.value, t_id: trd.e_id, description: p.description}))}
            const updatedTrd = update(trd, { $merge: { parameters: newParams } })
            resource.saveEntity(updatedTrd).then(value => onClickButtonRefresh())
        }
    };

    const onEdit = (e: any) => {
        const delEntity = JSON.parse(e.currentTarget.id);
        setIsSidebarOpen(true);
        const newItem = data.filter(parameter => parameter.deploymentName === selectedDeployment.label && parameter.name === delEntity.name)[0]
        setNewItem(newItem)
    };

    const onCopy = (e: any) => {
        const delEntity = JSON.parse(e.currentTarget.id);
        setIsSidebarOpen(true);
        const newItem = data.filter(parameter => parameter.deploymentName === selectedDeployment.label && parameter.name === delEntity.name)[0]
        newItem.id = undefined
        onSave(newItem)
    };

    const onCancel = () => {
        setNewItem(undefined)
    };

    const getContent = () =>  columns.filter((col, index) => col.hidden !== true && col.accessor !== 'actions')
        .map((col, index) => {
            return <TextField
                id={col.accessor}
                label={t(col.title)}
                style={{display: "block"}}
                value={newItem !== undefined ? newItem[col.accessor] : ""}
                onChange={e => {
                    // @ts-ignore
                    const newItem_ = {[e.id]: e.value}
                    setNewItem(newItem ? update(newItem, { $merge: newItem_ }) : newItem_)
                }}
            />
        })

    return (
        <div className={'play'}
             style={{['--play-height' as string]: `${selectedDeployment !== undefined && data.filter( parameter => parameter.deploymentName === selectedDeployment.label ) !== undefined && data.filter( parameter => parameter.deploymentName === selectedDeployment.label ).length > 3 ? 'calc(100% - 47px)' : 'unset'}`}}
        >
            <div className={'play-select'}>
                <Select
                    items={deploymentList && deploymentList.map((dep: any, index: number) => {
                        return {
                            label: dep.name,
                            id: index,
                        }})}
                    value={selectedDeployment}
                    onChange={(deploymentName: any) => {
                        setSelectedDeployment(deploymentName.value)
                    }}
                />
            </div>
            {variablesVisible &&
                <Table
                    columns={columns}
                    rows={getDataSource()}
                    separateRows={true}
                    size={"m"}
                    stickyHeader
                    withHeaderMenu
                    leftSide={leftSide}
                    rightSide={<></>}
                    stopIsProgressLineVisible={isLineVisibleTable}
                    emptyRowsPlaceholder={t('nodata')}
                />
            }
            {isSidebarOpen &&
                <SideBar
                    stateSidebar = {isSidebarOpen}
                    label = {t(typeSidebar)}
                    content = {getContent()}
                    onClose = {() => onClose()}
                    onSave = {() => onSave()}
                    onCancel = {() => onCancel()}
                />
            }
        </div>
    );
}

export default withTranslation()(Play);
