import './Processes.css'
import * as React from 'react';
import {useEffect, useState} from 'react';
import {withTranslation} from 'react-i18next'
import resource from "../../../Resource";

function Processes () {

    const [processes, setProcesses] = useState<any[]>([]);

    useEffect(() => {
        const newProcesses = resource.processes.map(item => `[${item.type}] ${JSON.stringify(item.props)}`)
        setProcesses(newProcesses)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[resource.processes.length]);

    return (
        <div className={'processes'}>
            {processes.map(value => <div>
                {value}
            </div>)}
        </div>
    );
}

export default withTranslation()(Processes);
