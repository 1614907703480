import './BottomPanel.css'
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next'
import {PropBottomPanel} from "./helpers";
import {Button} from "nx-design/Button";
import {IconFileLog} from "nx-design/IconFileLog";
import {IconHourglass} from "nx-design/IconHourglass";
import {IconLineage} from "nx-design/IconLineage";
import {IconCheck} from "nx-design/IconCheck";
import {ProgressLine} from "nx-design/ProgressLine";
import {Card} from "nx-design/Card";
import Logs from "./logs/Logs";
import {IconComponent} from "nx-design/__internal__/cjs-src/icons/_Icon/Icon";
import {IconClose} from "nx-design/IconClose";
import {IconErase} from "nx-design/IconErase";
import {IconSearch} from "nx-design/IconSearch";
import {IconWarning} from "nx-design/IconWarning";
import resource from "../../Resource";
import Processes from "./processes/Processes";
import {IconPlay} from "nx-design/IconPlay";
import Play from "./play/Play";
import {IconVariables} from "nx-design/IconVariables";
import Check from "./check/Check";
import Lineage from "./lineage/Lineage";
import {ButtonTooltip} from "../startPage/helpers";
import {IconCaretUp} from "nx-design/IconCaretUp";
import {IconCaretDown} from "nx-design/IconCaretDown";

export const cardProp = ['logs', 'processes', 'lineage', 'check', 'run', 'loading'] as const;
export type CardProp = typeof cardProp[number];
export const cardPropDefault: CardProp = cardProp[0];

export const bottomTypeProp = ['default', 'transformation', 'workflow'] as const;
export type BottomTypeProp = typeof bottomTypeProp[number];
export const bottomTypePropDefault: BottomTypeProp = bottomTypeProp[0];

function BottomPanel (props: PropBottomPanel) {

    const {
        activeObject,
        context,
        path
    } = props;

    const [cardValue, setCardValue] = useState<CardProp | undefined>(undefined);
    const [isProgressLineVisible, setProgressLineVisible] = useState<boolean>(false);
    const [isContextVisible, setContextVisible] = useState(false);
    const [clearLog, setClearLog] = useState(false);
    const [searchLog, setSearchLog] = useState(false);
    const [logError, setLogError] = useState<boolean>(false);
    const [isErrorFilter, setErrorFilter] = useState<boolean>(false);
    const [existsDeployment, setExistsDeployment] = useState<boolean>(false);
    const [processes, setProcesses] = useState<boolean>(false);
    const [bottomType, setBottomType] = useState<BottomTypeProp>(bottomTypePropDefault);
    const [playTransformation, setPlayTransformation] = useState(false);
    const [variablesVisible, setVariablesVisible] = useState(false);
    const [checkError, setCheckError] = useState(false);
    const [scrollDown, setScrollDown] = useState(false);
    const [scrollBegin, setScrollBegin] = useState(false);

    const { t } = useTranslation()

    const updateBottomType = () => {
        const updateType: BottomTypeProp =
            activeObject._type_ !== activeObject.name && activeObject._type_ === "etl.Transformation" ?
                bottomTypeProp[1] :
                activeObject._type_ !== activeObject.name && activeObject._type_ === "etl.Workflow" ?
                    bottomTypeProp[2] :
                    bottomTypeProp[0]

        setBottomType(updateType);
    }

    useEffect(() => {
        updateBottomType()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeObject]);

    const onSelectTab = (event: any) => {
        if (cardValue === event.currentTarget.id) {
            setCardValue(undefined);
            setContextVisible(false)
        }
        else {
            setCardValue(event.currentTarget.id);
            setContextVisible(true)
        }
    }

    const createButton = (id: string, icon: IconComponent, label: string, cardValueButton: CardProp) => <Button
        id={id}
        className={'bottom-panel-button'}
        size="s"
        iconSize={"xs"}
        view="clear"
        iconLeft={icon}
        label={t(label)}
        onClick={event => onSelectTab(event)}
        style={{
            ['--bottom-panel-bg-button-color' as string]: `${
                cardValue === cardValueButton ? 
                    'var(--color-base-base-grey-2)' : 
                    'unset'
            }`,
            ['--bottom-panel-typo-button-color' as string]: `${
                ( cardValueButton === cardPropDefault && logError ) ? 
                    'var(--color-base-error-magenta-6)' :
                    ( cardValueButton === cardProp[1] && processes ) ?
                        'var(--color-base-path-blue-6)' :
                        ( cardValueButton === cardProp[3] && checkError ) ?
                            'var(--color-base-error-magenta-6)' :
                            cardValue === cardValueButton ? 
                                'var(--color-base-path-blue-6)' : 
                                'unset'
            }`,
        }}
    />;

    const onCloseCard = () => {
        setContextVisible(false);
        setCardValue(undefined);
    }

    const onClear = () => {
        setClearLog(true)
        setLogError(false)
    }

    const onScrollBegin = () => {
        setScrollBegin(!scrollBegin);
    }

    const onScrollDown = () => {
        setScrollDown(!scrollDown);
    }

    const openFullLogFile = () => {
        if (window !== null) {
            const url: String = window.location.origin + "/actuator/logfile";
            // @ts-ignore
            window.open(url, '_blank').focus();
        }
    }

    useEffect(() => {
        const rows = resource.logControl.logs
        // @ts-ignore
        const errorLogs = rows.length !== 0 ? rows.filter(value => value.stacktrace !== undefined) : [];
        if (errorLogs.length !== 0) {setLogError(true)}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[resource.logControl.logs, isErrorFilter]);

    useEffect(() => {
        const rows = resource.processes
        if (rows.length !== 0) {
            setProcesses(true)
            setProgressLineVisible(true)
        }
        else {
            setProcesses(false)
            setProgressLineVisible(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[resource.processes.length]);

    useEffect(() => {
        if (bottomType === bottomTypeProp[1] && path.length < 4) {
            setContextVisible(false)
            setCardValue(undefined)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[path]);

    return (
        <div className={'bottom-panel'}>
            {
                isContextVisible &&
                <Card form={"square"} shadow={false} className={'bottom-panel-card'}>
                    <div className={'card-left'}>
                        <div className={'card-top-side'}>
                            <ButtonTooltip
                                onlyIcon
                                view="ghost"
                                iconLeft={IconClose}
                                iconSize={"xs"}
                                onClick={() => {onCloseCard()}}
                                tooltipProps={{ content: t('close')}}
                            />
                            {cardValue === cardPropDefault &&
                                <ButtonTooltip
                                    className={'bottom-panel-button-search'}
                                    onlyIcon
                                    view="ghost"
                                    iconLeft={IconSearch}
                                    iconSize={"xs"}
                                    onClick={() => {setSearchLog(!searchLog)}}
                                    tooltipProps={{
                                        content: t('search'),
                                        style: {
                                            ['--bottom-panel-button-search-color' as string]: `${searchLog ? 'var(--color-base-base-grey-2)' : 'none'}`,
                                            ['--bottom-panel-button-search-color-icon' as string]: `${searchLog ? 'var(--color-base-path-blue-6)' : 'none'}`,
                                        }
                                    }}
                                />
                            }
                            {cardValue === cardPropDefault && logError &&
                                <div className={'bottom-panel-button-error-container'}>
                                    <ButtonTooltip
                                        className={'bottom-panel-button-error'}
                                        onlyIcon
                                        view="ghost"
                                        iconLeft={IconWarning}
                                        iconSize={"xs"}
                                        onClick={() => {setErrorFilter(!isErrorFilter)}}
                                        tooltipProps={{
                                            content: t('logError'),
                                            style: {
                                                ['--bottom-panel-button-error-color' as string]: `${isErrorFilter ? 'var(--color-base-base-grey-2)' : 'none'}`,
                                            }
                                        }}
                                    />
                                </div>
                            }
                            {cardValue === cardProp[4] &&
                                <div>
                                    <ButtonTooltip
                                        className={'bottom-panel-button-play'}
                                        onlyIcon
                                        view="ghost"
                                        iconLeft={IconPlay}
                                        iconSize={"xs"}
                                        disabled={existsDeployment}
                                        onClick={() => {setPlayTransformation(true)}}
                                        tooltipProps={{content: t('run')}}
                                    />
                                    <ButtonTooltip
                                        className={'bottom-panel-button-variables'}
                                        onlyIcon
                                        view="ghost"
                                        iconLeft={IconVariables}
                                        iconSize={"xs"}
                                        onClick={() => {setVariablesVisible(!variablesVisible)}}
                                        tooltipProps={{
                                            content: t('variables'),
                                            style: {
                                                ['--bottom-panel-button-variables-color' as string]: `${variablesVisible ? 'var(--color-base-base-grey-2)' : 'none'}`,
                                            }
                                        }}
                                    />
                                 </div>
                            }
                        </div>
                        {cardValue === cardPropDefault &&
                            <div className={'card-bottom-side'}>
                                <div className={'card-bottom-side-before'}>
                                    <ButtonTooltip
                                        onlyIcon
                                        view="ghost"
                                        iconLeft={IconFileLog}
                                        iconSize={"xs"}
                                        onClick={() => {openFullLogFile()}}
                                        tooltipProps={{content: t('openfulllog')}}
                                    />
                                </div>
                                <div className={'card-bottom-side-before-up'}>
                                    <ButtonTooltip
                                        onlyIcon
                                        view="ghost"
                                        iconLeft={IconCaretUp}
                                        iconSize={"xs"}
                                        onClick={() => {onScrollBegin()}}
                                        tooltipProps={{content: t('scrolltobegin')}}
                                    />
                                </div>
                                <div className={'card-bottom-side-before-down'}>
                                    <ButtonTooltip
                                        onlyIcon
                                        view="ghost"
                                        iconLeft={IconCaretDown}
                                        iconSize={"xs"}
                                        onClick={() => {onScrollDown()}}
                                        tooltipProps={{content: t('scrolltoend')}}
                                    />
                                </div>

                                <div className={'card-bottom-side-before-clear'}>
                                    <ButtonTooltip
                                        onlyIcon
                                        view="ghost"
                                        iconLeft={IconErase}
                                        iconSize={"xs"}
                                        onClick={() => {onClear()}}
                                        tooltipProps={{content: t('clear')}}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div className={'card-right'}>
                        {cardValue === cardPropDefault &&
                            <Logs
                                clearLog={clearLog}
                                scrollDown={scrollDown}
                                scrollBegin={scrollBegin}
                                searchLog={searchLog}
                                isErrorFilter={isErrorFilter}
                            />}
                        {cardValue === cardProp[1] && <Processes/>}
                        {cardValue === cardProp[2] &&
                            <Lineage
                                context={context}
                                activeObject={activeObject}
                            />
                        }
                        {cardValue === cardProp[3] &&
                            <Check
                                context={context}
                                activeObject={activeObject}
                                setCheckRow={(value) => setCheckError(value)}
                            />
                        }
                        {cardValue === cardProp[4] &&
                            <Play
                                activeObject={activeObject}
                                setError={() => {setErrorFilter(true)}}
                                setDeployment={(value) => {setExistsDeployment(value)}}
                                playTransformation={playTransformation}
                                setPlay={() => {setPlayTransformation(!playTransformation)}}
                                variablesVisible={variablesVisible}
                                context={context}
                            />
                        }
                    </div>

                </Card>
            }
            {isProgressLineVisible && <ProgressLine size={"s"}/>}
            <div className={'bottom-panel-buttons'}>
                {createButton('logs', IconFileLog, 'logs', cardPropDefault)}
                {createButton('processes', IconHourglass, 'processes', cardProp[1])}
                {bottomType === bottomTypeProp[1] && createButton('lineage', IconLineage, 'lineage', cardProp[2])}
                {bottomType === bottomTypeProp[1] && createButton('check', IconCheck, 'check', cardProp[3])}
                {bottomType === bottomTypeProp[1] && createButton('run', IconPlay, 'run', cardProp[4])}
                {/*{bottomType === bottomTypeProp[1] && createButton('loading', IconCloudUpload, 'loading', cardProp[5])}*/}
            </div>
        </div>
    );
}

export default withTranslation()(BottomPanel);
