import './StartPage.css'
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next'
import HeaderStartPage from './header/HeaderStartPage';
import {Grid, GridItem} from 'nx-design/Grid';
import {ReactComponent as AppLogo} from '../../img/logoFull.svg';
import {ReactComponent as AppLogoExtended} from '../../img/logoFullextended.svg';
import {PropStartPage, renderGrid, viewProp, ViewProp, viewPropDefault} from './helpers';
import LeftMenu from './leftMenu/LeftMenu';
import RightMenu from './rightMenu/RightMenu';
import {Button} from 'nx-design/Button';
import {IconFullScreen} from 'nx-design/IconFullScreen';
import {IconFullScreenExit} from 'nx-design/IconFullScreenExit';
import {useFlag} from 'nx-design/useFlag';

function StartPage (props: PropStartPage) {

    const {
        setLang,
        onSelectObject,
        pathHistory,
        push,
        pushHref,
        context
    } = props;

    const [view, setView] = useState<ViewProp>(viewProp[0]);

    const [animationOn, setAnimationOn] = useFlag(false);

    const { t } = useTranslation()

    const clickView = () => {
        setView( view === viewProp[0] ? viewProp[1] : viewProp[0]);
        setAnimationOn.on();
    };

    useEffect(() => {
        document.title = 'Home Page - Datagram';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const classNameAnimationLogo =
        animationOn && view === viewPropDefault ?
            'start-page-logo-compact StartPageViewCompactLogo' :
        animationOn && view !== viewPropDefault ?
            'start-page-logo-extended StartPageViewExtendedLogo' :
        view === viewPropDefault ?
            'start-page-logo-compact' :
                'start-page-logo-extended';

    const classNameAnimationButton =
        animationOn && view === viewPropDefault ?
            'start-page-button StartPageViewCompactButton' :
            animationOn && view !== viewPropDefault ?
                'start-page-button StartPageViewExtendedButton' :
                'start-page-button';

    return (
        <div className='startPage'>
            <HeaderStartPage
                setLang={setLang}
                pushHref={pushHref}
                onSelectObject={onSelectObject}
                pathHistory={pathHistory}
                push={push}
                context={context}
            />
            <Grid cols={`${renderGrid(view, 1)}`}>
                <GridItem col={`${renderGrid(view, 2)}`}/>

                <GridItem
                    col={`${renderGrid(view, 3)}`}
                    className={classNameAnimationLogo}
                >
                    {view === viewPropDefault ? <AppLogo/> : <AppLogoExtended/>}
                </GridItem>

                <GridItem col={`${renderGrid(view, 2)}`}/>

                <GridItem col={`${renderGrid(view, 3)}`}>
                    <Button
                        label={t(view)}
                        view={'link'}
                        iconLeft={view === viewPropDefault ? <IconFullScreen className={'start-page-button-icon'} size='xs'/> : <IconFullScreenExit className={'start-page-button-icon'} size='xs'/>}
                        className={classNameAnimationButton}
                        onlyIcon={false}
                        onClick={() => clickView()}
                    />
                </GridItem>

                <GridItem col={`${renderGrid(view, 4)}`}/>

                <GridItem col={`${renderGrid(view, 5)}`}>
                    <LeftMenu
                        view={view}
                        animationOn={animationOn}
                        push={push}
                    />
                </GridItem>

                <GridItem col={`${renderGrid(view, 6)}`}>
                    <RightMenu
                        pathHistory={pathHistory}
                        push={push}
                        view={view}
                        animationOn={animationOn}
                    />
                </GridItem>

                <GridItem col={`${renderGrid(view, 7)}`}/>
            </Grid>
        </div>

    );

}

export default withTranslation()(StartPage);
