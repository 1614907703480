
const cupOfCoffee = "   ) \n  (( \n(\"\"\"\")o \n\"\"\"\"\"\""
const colorList = [
    "#c5e5e1",
    "#ffbf97",
    "#f1dbcf",
    "#f27878",
    "#92D4E8",
    "#9F8BE5",
    "#fbe864",
    "#f58db6",
    "#5975f5",
    "#a0cf31",
    "#33a5ff",
    "#ff3131",
    "#ff6700",
    "#cfff00",
    "#9e0bff"
]

export {cupOfCoffee, colorList}