import './HeaderStartPage.css'
import * as React from 'react';
import {useRef, useState} from 'react';
import {useTranslation, withTranslation} from "react-i18next"
import i18n from "../../../i18n";
import {IconGlobe} from "nx-design/__internal__/cjs-src/icons/IconGlobe/IconGlobe";
import {Header, HeaderButton, HeaderModule} from "nx-design/Header";
import {IconInfo} from "nx-design/IconInfo";
import {IconSignOut} from "nx-design/IconSignOut";
import {Typography} from "nx-design/Typography";
import {IconUser} from "nx-design/IconUser";
import {Button} from "nx-design/Button";
import {IconDictionary} from "nx-design/IconDictionary";
import resource from '../../../Resource'
import {langProp, LangProp, pathItem, recursionItems, splitName} from '../helpers'
import {ContextMenu, ContextMenuItemDefault} from "nx-design/ContextMenu";
import {getBasename} from "../../../utils/meta";
import {IconHistory} from "nx-design/IconHistory";
import {withTooltip} from "nx-design/withTooltip";
import {Breadcrumbs} from "nx-design/Breadcrumbs";

type PropsHeaderStartPage = {
    setLang: (lang: LangProp) => void;
    pushHref: (href: string) => void;
    onSelectObject: (arg: object) => void;
    pathHistory: pathItem[][];
    push: (path: string) => void;
    layoutPage?: boolean;
    path?: any[];
    context: any;
};

function HeaderStartPage (props: PropsHeaderStartPage) {

    const {
        setLang,
        pushHref,
        onSelectObject,
        pathHistory,
        push,
        layoutPage,
        path,
        context
    } = props;

    const { location } = window;

    const { t } = useTranslation();

    const [isOpenUser, setIsOpenUser] = useState(false);
    const [isOpenHistory, setIsOpenHistory] = useState(false);

    const items: ContextMenuItemDefault[] = [
        // {
        //     label: 'branch',
        //     leftIcon: IconBranch,
        //     key: 'branch',
        //     subMenu: Object.keys(context.branchInfo.branches).map(branch => {
        //         return {
        //             label: branch,
        //             leftIcon: context.branchInfo.current === branch ? IconCheck : undefined,
        //             key: branch,
        //             onClick: ((e) => {
        //                 context.setCurrentBranch(e.item.label);
        //                 setIsOpenUser(false);
        //             })
        //         }
        //     })
        // },
        {
            label: 'manual',
            leftIcon: IconDictionary,
            key: 'manual',
            subMenu: [
                {
                    label: 'rus',
                    leftIcon: IconDictionary,
                    key: 'manual-rus',
                    onClick: (() => {
                        pushHref(`${getBasename(location.pathname)}manual/UserManualRU/UserManualRU.html`)
                        setIsOpenUser(!isOpenUser)
                    })
                },
                {
                    label: 'eng',
                    leftIcon: IconDictionary,
                    key: 'manual-eng',
                    onClick: (() => {
                        pushHref(`${getBasename(location.pathname)}manual/UserManualEN/UserManualEN.html`)
                        setIsOpenUser(!isOpenUser)
                    })
                }
            ]
        },
    ];

    const ButtonApplicationInfo = withTooltip({ content: t('applicationInfo'), textSize: "m" })(Button);
    const ButtonHistory = withTooltip({ content: t('history'), textSize: "m" })(Button);
    const ButtonLogout = withTooltip({ content: t('logout'), textSize: "m", direction: 'downLeft' })(Button);

    const itemHistory: ContextMenuItemDefault[] =
        pathHistory ? pathHistory.map((p: pathItem[], index) => {
                const m: pathItem = p[p.length - 1]
                const item: ContextMenuItemDefault = {
                    label: <Typography size={"s"} className={'header-item-history'}>{
                        t(splitName(m._type_)) + " " + m.name
                    }</Typography>,
                    key: JSON.stringify(p),
                    onClick: (e: any) => push(JSON.parse(e.item.key)),
                    leftIcon: recursionItems().filter((r:any) => r.label === m._type_)[0].leftIcon
                }
                return item;
            } )
            : [];

    const ref = useRef(null);
    const refHistory = useRef(null);

    const handleClick = () => {
        setLang( i18n.language === langProp[0] ? langProp[1] : langProp[0]);
    };

    const getIcon = (name: string) => {
        const items = recursionItems().filter((r: any) => r.label === name)[0]
        return items === undefined ? items : items.leftIcon
    };

    const breadcrumbPages = layoutPage ? path!.map((m:any, index) => {
        return {
            label: index < 3 ? t(splitName(m.name)) : m.name,
            onClick: () => {
                onSelectObject(m)
            },
            icon: getIcon(m.name) || getIcon(m._type_)
        }
    }) : [];

    return (
        <Header
            className="header"
            leftSide={layoutPage &&
                <>
                    <Breadcrumbs
                        items={breadcrumbPages}
                        fitMode={"scroll"}
                    />
                </>
            }
            rightSide={
                <>
                    <HeaderModule className={"module-lang"}>
                        <HeaderButton
                            form={"default"}
                            onlyIcon={false}
                            id="button-lang"
                            className="button-lang button-header"
                            iconLeft={<IconGlobe className="icon-lang" size={"xs"}/>}
                            label={i18n.language.split("-")[1]}
                            onClick={() => handleClick()}
                        />
                    </HeaderModule>

                    <HeaderModule indent="s" >
                        <ButtonApplicationInfo
                            id="button-info"
                            form={"default"}
                            className="button-header"
                            view={"clear"}
                            iconLeft={<IconInfo size={"xs"}/>}
                            onClick={ () => {
                                let path = [
                                    {name: "Datagram", _type_: "ui3.Application"},
                                    {name: "ApplicationInfo", _type_: "ui3.ApplicationInfo"},
                                ];
                                push(JSON.parse(JSON.stringify(path)))
                            }
                        }
                        />
                    </HeaderModule>

                    <HeaderModule indent="s">
                        <ButtonHistory
                            id="button-history"
                            form={"default"}
                            className="button-header"
                            view={"clear"}
                            iconLeft={<IconHistory size={"xs"}/>}
                            onClick={() => setIsOpenHistory(!isOpenHistory)}
                            ref={refHistory}
                        />
                        <ContextMenu
                            isOpen={isOpenHistory}
                            items={itemHistory}
                            anchorRef={refHistory}
                            direction="downCenter"
                            subMenuDirection="leftStartUp"
                            onClickOutside={() => setIsOpenHistory(false)}
                            size={"s"}
                            offset={"m"}
                        />
                    </HeaderModule>

                    <HeaderModule indent="s" >
                        <Button
                            id="button-menu"
                            form={"default"}
                            view={"primary"}
                            iconLeft={<IconUser size={"xs"}/>}
                            onClick={() => setIsOpenUser(!isOpenUser)}
                            ref={ref}
                        />
                        <ContextMenu
                            className={'header-context-menu-user'}
                            isOpen={isOpenUser}
                            items={items}
                            getItemLabel={(item:any) => t(item.label)}
                            anchorRef={ref}
                            direction="downCenter"
                            subMenuDirection="leftStartUp"
                            onClickOutside={() => setIsOpenUser(false)}
                            size={"s"}
                            offset={"m"}
                        />
                    </HeaderModule>

                    <HeaderModule indent="s">
                        <Typography className="typo-username">{context.user}</Typography>
                    </HeaderModule>

                    <HeaderModule indent="l">
                        <ButtonLogout
                            id="button-signout"
                            className="button-header"
                            form={"default"}
                            view={"clear"}
                            iconLeft={<IconSignOut size={"xs"}/>}
                            onClick={() => resource.logout()}
                        />
                    </HeaderModule>
                </>
            }
        />
    );

}

export default withTranslation()(HeaderStartPage);
