import './LeftLayoutMenu.css'
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next'
import {Button} from "nx-design/Button";
import {IconCloseMenu} from "nx-design/IconCloseMenu";
import {Header} from "nx-design/Header";
import {ReactComponent as AppLogo} from "../../../img/logo.svg";
import {IconTree} from "nx-design/IconTree";
import {IconHistory} from "nx-design/IconHistory";
import {
    ButtonTooltip,
    Dependency,
    getItemsMenu,
    getSubItemsMenu,
    Item,
    items,
    linkItem,
    pathItem,
    recursionItems,
    renderIcon,
    splitName
} from "../../startPage/helpers";
import {IconOpenMenu} from "nx-design/IconOpenMenu";
import {Typography} from "nx-design/Typography";
import ObjectExplorer from "./ObjectExplorer";
import _ from "lodash";
import {getClassDef} from "../../../model";
import resource from "../../../Resource";
import {IconPlusC} from "nx-design/IconPlusC";
import {IconArrowRight} from "nx-design/IconArrowRight";
import {typeSidebarProp} from "../../sideBar/SideBar";

export type PropLeftLayoutMenu = {
    activeObject: pathItem;
    path: any[];
    push: (path: string) => void;
    pathHistory: pathItem[][];
    context: any;
    openRightSideBarLite: (isSidebarOpen: any, openObject: any, typeSidebar: any) => void;
};

export const menuProp = ['subItems', 'structure', 'history'] as const;
export type MenuProp = typeof menuProp[number];
export const menuPropDefault: MenuProp = menuProp[0];

function LeftLayoutMenu (props: PropLeftLayoutMenu) {

    const {
        activeObject,
        path,
        push,
        pathHistory,
        context,
        openRightSideBarLite
    } = props;

    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [subItems, setSubItems] = useState<Item[]>([]);
    const [dependency, setDependency] = useState<Dependency[]>([]);
    const [itemLabel, setItemLabel] = useState<string>(path ? path[1].name : "");
    const [menuType, setMenuType] = useState<MenuProp>(menuPropDefault);

    const { t } = useTranslation();

    const updateSelectObject = (e: any) => {
        setMenuType(menuProp[0]);
        const subItems = getSubItemsMenu(e.currentTarget.name);
        let path = [
            {name: "Datagram", _type_: "ui3.Application"},
            {name: e.currentTarget.name, _type_: e.currentTarget.id},
            {name: subItems[0].key, _type_: subItems[0].type}
        ];
        push(JSON.parse(JSON.stringify(path)))
    };

    const updateSubItems = (name: string) => {
        const subItems = getSubItemsMenu(name);
        setSubItems(subItems);
        setItemLabel(path ? path[1].name : "");
    };

    useEffect(() => {
        setItemLabel(activeObject.name);

        if (activeObject.name === "ApplicationInfo") {
            setDependency([])
        }
        else if (activeObject._type_ !== "ecore.EClass" && activeObject._type_ !== activeObject.name && activeObject._type_ !== "ui3.Module") {
            getLinkedClasses(activeObject)
        }
        else if (activeObject._type_ === "ecore.EClass" || activeObject._type_ === activeObject.name || activeObject._type_ === "ui3.Module") {
            updateSubItems(activeObject.name);
            setDependency([])
        }

        if (context.updateLeftLayoutMenu) {context.updateContext({ updateLeftLayoutMenu: false })}

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeObject, context.updateLeftLayoutMenu]);

    const onClickSubMenuRight = (e: any) => {
        setMenuType(menuProp[0]);
        const items = getItemsMenu(e.currentTarget.name);
        let path = [
            {name: "Datagram", _type_: "ui3.Application"},
            {name: items !== undefined && items.key, _type_: items !== undefined && items.type},
            {name: e.currentTarget.name, _type_: e.currentTarget.id}
        ];
        push(JSON.parse(JSON.stringify(path)))
    };

    const onClickDependency = (e: any) => {
        openRightSideBarLite(true, {_type_: e.currentTarget.name, name: e.currentTarget.value, e_id: e.currentTarget.id}, typeSidebarProp[0])
    };

    const onClickAddDependency = (e: any) => {
        openRightSideBarLite(true, {_type_: e.currentTarget.id, name: "", e_id: undefined}, typeSidebarProp[1])
    };

    const onClickOpenDependency = (e: any) => {
        if (path !== undefined) {
            path.pop();
            path.push({_type_: e.currentTarget.name, name: e.currentTarget.value, e_id: e.currentTarget.id});
            push(JSON.parse(JSON.stringify(path)))
        }
        openRightSideBarLite(false, undefined, undefined);
    };

    const onClickLogo = () => {
        let path = [
            {name: "Datagram", _type_: "ui3.Application"}
        ];
        push(JSON.parse(JSON.stringify(path)))
    };

    const openMenuStructure = (e: any) => {
        if (menuType === menuProp[1]) {setMenuType(menuProp[0]);}
        else {setMenuType(menuProp[1]);}
    };

    const openMenuHistory = (e: any) => {
        if (menuType === menuProp[2]) {setMenuType(menuProp[0]);}
        else {setMenuType(menuProp[2]);}
    };

    const findLinkedObjects = (root: pathItem, link: linkItem, cb: any) => {
        const clean = (inst: any) => {
            const { _type_, e_id, name } = inst
            return _.omitBy({ _type_, e_id, name }, _.isUndefined)
        }
        if (link.backReference || link.query) {
            if (root.e_id) {
                let query = undefined
                if (link.query && root.e_id) {
                    // eslint-disable-next-line no-template-curly-in-string
                    query = link.query.replace("${e_id}", root.e_id)
                }
                else if (link.backReference) {
                    query = `select type(t), t.e_id, t.name from ${link._type_} t where t.${link.backReference}.e_id = ${root.e_id} order by t.name`
                }
                resource.query(`/api/teneo/select/${query}`)
                    .then(list => list.map((row: any) => _.zipObject(['_type_', 'e_id', 'name'], row)))
                    .then(data => {
                        if (data !== undefined) {
                            const upData: any[] = []
                            data.forEach((d: any) => {
                                let addOrNot = true;
                                if (upData.length !== 0) {
                                    upData.forEach((ud: any) => {
                                        if (ud.e_id === d.e_id) {
                                            addOrNot = false
                                        }
                                    })
                                }
                                if (addOrNot || upData.length === 0) { upData.push(d) }
                            })

                            cb(upData)
                        }
                        else { cb(data) }
                    })
            }
            else {
                cb([])
            }
        } else if (link.attribute) {
            cb(getClassDef(link._type_).instances
                .filter((i: any) => { // eslint-disable-line
                const object = getClassDef(root._type_).instances.find((c: any) => {
                    return c.name === root.name
                })
                if (link.attribute) { return object[link.attribute].includes(i.name) }})
                .map((inst: any) => clean(inst))
            )
        } else if (link.name === "Objects") {
            const getClass = getClassDef(root.name)
            if (getClass.query) {
                resource.query(`/api/teneo/select/${getClass.query}`)
                    .then(list => list.map((row: any) => _.zipObject(['_type_', 'e_id', 'name'], row)))
                    .then(data => cb(data))
            }
            else {
                resource.getSimpleSelect(root.name, ["name"]).then(data => cb(_.sortBy(data, (item) => item.name.toLowerCase())))
            }
        } else {
            cb(getClassDef(link._type_).instances.map((inst: any) => clean(inst)))
        }
    }

    const getLinkedClasses = (activeObject: pathItem) => {
        const classes = _.get(getClassDef(activeObject._type_), "linkedClasses") || [];

        if (classes.length === 0) {
            setItemLabel(activeObject.name);
            setSubItems([]);
            return;
        }
        const linkedClasses: any[] = [];
        classes.forEach((link: any) => {
            if (link)
            // @ts-ignore
            findLinkedObjects(activeObject, link, linkedObjects => {
                linkedClasses.push([link, linkedObjects]);

                const dependency: Dependency[] = linkedClasses.map(([link, linkedObjects]) => {

                    return {
                        label: link.name,
                        type: link._type_,
                        e_id: link.e_id,
                        leftIcon: link._type_ && renderIcon(recursionItems().filter((r:any) => r.label === link._type_)[0], "xs"),
                        backReference: link.backReference,
                        subMenu: linkedObjects.map((l:any) => {
                            return {
                                label: l.name,
                                type: l._type_,
                                e_id: l.e_id
                            }
                        })
                        }
                })
                setDependency(_.sortBy(dependency, [o => o.label]))
                setItemLabel(activeObject.name)
            })
        })

    }

    // @ts-ignore
    return (
        <div className='leftLayoutMenu'>
            <div className={'layout-menu-grid'}>
                <div className={'layout-menu-left-grid header-height'}>
                    <div className={"collapse-icon header-height"}>
                        <Button
                            size={"l"}
                            view={"ghost"}
                            iconLeft={isOpen ? IconCloseMenu : IconOpenMenu}
                            onClick={() => setIsOpen(!isOpen)}
                        />
                    </div>

                    <div className={'layout-menu-left-grid bottom-height'}>
                        <div className={"top-side"}>
                            {items[0].subMenu !== undefined && items[0].subMenu.map((m) => {
                                return <div key={m.type + m.label}>
                                    <ButtonTooltip
                                        id={m.type}
                                        name={m.label}
                                        size={"l"}
                                        iconLeft={renderIcon(m, "m")}
                                        view={"ghost"}
                                        onlyIcon
                                        onClick={(e: any) => updateSelectObject(e)}
                                        tooltipProps={{
                                            content: t(m.label),
                                            style: {['--left-menu-button-color' as string]:
                                                    `${path && m.label === path[1].name && menuType === menuProp[0] ?
                                                        'var(--color-base-path-blue-6)' :
                                                        'none'}`}
                                        }}
                                    />
                                </div>
                            })}
                        </div>
                        <div className={"bottom-side"}>
                            <ButtonTooltip
                                size={"l"}
                                iconLeft={IconTree}
                                view={"ghost"}
                                onClick={(e: any) => openMenuStructure(e)}
                                tooltipProps={{
                                    content: t('structure'),
                                    style: {['--left-menu-button-color' as string]:
                                            `${menuType === menuProp[1] ?
                                                'var(--color-base-path-blue-6)' :
                                                'none'}`}
                                }}
                            />
                            <ButtonTooltip
                                size={"l"}
                                iconLeft={IconHistory}
                                view={"ghost"}
                                onClick={(e: any) => openMenuHistory(e)}
                                tooltipProps={{
                                    content: t('history'),
                                    style: {['--left-menu-button-color' as string]:
                                            `${menuType === menuProp[2] ?
                                                'var(--color-base-path-blue-6)' :
                                                'none'}`}
                                }}
                            />
                        </div>
                    </div>

                </div>

                {isOpen && <div className={'layout-menu-right-grid'}>
                    <Header
                        className="header-height"
                        leftSide={<div className="header-logo">
                            <AppLogo onClick={()=> onClickLogo()}/>
                        </div>}
                    />
                    <div className={'layout-menu-right-grid'}>

                        {menuType === menuProp[0] && <div>
                            <div className={'right-grid-typo-container'}>
                                <Typography className={'right-grid-typo'} size={"l"} weight="bold">
                                    { ( path && path.length > 3 ) || dependency.length !== 0 ? itemLabel : t(itemLabel) }
                                </Typography>
                            </div>

                            {dependency.length === 0 && subItems.length === 0 &&
                                <div className={'right-grid-dependency'}>
                                    <div className={'right-grid-not-found-dependency'}>{t('nodata')}</div>
                                </div>
                            }

                            {dependency.length === 0 && subItems.map((value: Item) => {
                                return <div
                                    className={'right-grid-button'}
                                    key={value.label + value.key}
                                >
                                    <Button
                                        id={value.key}
                                        name={value.label}
                                        width={"full"}
                                        view={"clear"}
                                        style={{['--left-menu-button-color' as string]:
                                                `${value.key === activeObject.name ? 
                                                    'var(--color-base-path-blue-6)' : 
                                                    'none'}`}}
                                        iconLeft={renderIcon(recursionItems().filter((r:any) => r.label === value.label)[0], "xs")}
                                        label = {t(splitName(value.label))}
                                        onClick={(e: any) => onClickSubMenuRight(e)}
                                    />
                                </div>
                            })}

                            {dependency.length !== 0 && dependency.map((value: Dependency) => {
                                return <div
                                    className={'right-grid-dependency'}
                                    key={value.label + value.e_id}
                                >
                                    <div>
                                        {value.leftIcon}
                                        <Typography className={'right-grid-typo-dependency'} size={"m"} > {t(splitName(value.label) + ".caption", { ns: 'references' })} </Typography>
                                        {value.backReference && <ButtonTooltip
                                            view={'clear'}
                                            onlyIcon
                                            iconRight={IconPlusC}
                                            size={'xs'}
                                            name={value.label}
                                            id={value.type}
                                            onClick={event => onClickAddDependency(event)}
                                            tooltipProps={{content: t('create')}}
                                        />}
                                    </div>

                                    <div className={'right-grid-container-dependency'}>
                                        {value.subMenu !== undefined && value.subMenu.length !== 0 ? value.subMenu.map((subValue: Item, index: number) => {
                                                return <div
                                                    key={subValue.label + subValue.key + index}
                                                    className={'right-grid-button-dependency'}
                                                >
                                                    <Button
                                                        id={subValue.e_id}
                                                        name={subValue.type}
                                                        view={"link"}
                                                        value={subValue.label}
                                                        label={subValue.label}
                                                        onClick={(e: any) => onClickDependency(e)}
                                                    />
                                                    {(subValue.type === "rt.LivyServer" ||
                                                            subValue.type === "rt.Oozie" ||
                                                            subValue.type === "etl.Transformation" ||
                                                            subValue.type === "etl.Workflow"
                                                        ) &&
                                                        <ButtonTooltip
                                                            view={'clear'}
                                                            onlyIcon
                                                            iconRight={IconArrowRight}
                                                            size={'xs'}
                                                            name={subValue.type}
                                                            id={subValue.e_id}
                                                            value={subValue.label}
                                                            onClick={event => onClickOpenDependency(event)}
                                                            tooltipProps={{content: t('open')}}
                                                        />}
                                                </div>
                                            }) :
                                            <div className={'right-grid-not-found-dependency'}>{t('nodata')}</div>
                                        }
                                    </div>
                                </div>
                            })}
                        </div>}

                        {menuType === menuProp[1] && <div>
                            <div className={'right-grid-typo-container'}>
                                <Typography className={'right-grid-typo'} size={"l"} weight="bold"> {t('explore')} </Typography>
                            </div>
                            <ObjectExplorer
                                {...props}
                            />
                        </div>}

                        {menuType === menuProp[2] && <div>
                            <div className={'right-grid-typo-container'}>
                                <Typography className={'right-grid-typo'} size={"l"} weight="bold"> {t('lastviewed')} </Typography>
                            </div>
                            {pathHistory ? pathHistory.map((p: pathItem[], index) => {
                                const m: pathItem = p[p.length - 1]
                                return <div
                                    className={'right-grid-button'}
                                    key={m.name + index}
                                >
                                    <Button
                                        id={m.name}
                                        width={"full"}
                                        view={"link"}
                                        name={JSON.stringify(p)}
                                        onClick={(e: any) => push(JSON.parse(e.currentTarget.name))}
                                        iconLeft={renderIcon(recursionItems().filter((r:any) => r.label === m._type_)[0], "s", "right-menu-icon")}
                                        label={m.name}
                                        style={{['--left-menu-button-color' as string]: 'var(--color-base-path-blue-6)'}}
                                    />
                                </div>
                            }) : []}
                        </div>}

                    </div>
                </div>}

            </div>
        </div>
    );

}

export default withTranslation()(LeftLayoutMenu);