import './SideBar.css'
import * as React from 'react';
import {useTranslation, withTranslation} from 'react-i18next'
import {Sidebar} from "nx-design/Sidebar";
import {Typography} from "nx-design/Typography";
import {Button} from "nx-design/Button";
import {IconSave} from "nx-design/IconSave";
import {IconClose} from "nx-design/IconClose";
import {ProgressLine} from "nx-design/ProgressLine";
import {IconBlock} from "nx-design/IconBlock";
import {IconDelete} from "nx-design/IconDelete";

export const typeSidebarProp = ['editObject', 'createObject'] as const;
export type TypeSidebarProp = typeof typeSidebarProp[number];
export const typeSidebarPropDefault: TypeSidebarProp = typeSidebarProp[0];

export type PropSideBar = {
    stateSidebar: boolean;
    label: string;
    actionButton?: React.ReactNode;
    content: React.ReactNode;
    onClose: () => void;
    onSave: () => void;
    onCancel: () => void;
    onDelete?: () => void;
    isProgressLineVisible?: boolean;
};

function SideBar (props: PropSideBar) {

    const {
        stateSidebar,
        label,
        actionButton,
        content,
        onClose,
        onSave,
        onCancel,
        onDelete,
        isProgressLineVisible,
    } = props;

    const { t } = useTranslation();

    return (
        <Sidebar
            isOpen={stateSidebar}
            position={"right"}
            hasOverlay={false}
            includeSpace={true}
            className={'sidebar'}
            width={'auto'}
        >
            <Sidebar.Content>
                <div className={'sidebar-header-container'}>
                    <div className={'sidebar-header-typo-container'}>
                        <Typography
                            className={'sidebar-header-typo'}
                            size="l"
                            view="primary"
                            weight={"medium"}
                        >
                            {label}
                        </Typography>
                        <Button
                            view={"clear"}
                            onlyIcon
                            iconLeft={IconClose}
                            onClick={() => onClose()}
                        />
                    </div>
                    {actionButton &&
                        <div className={'sidebar-actions-panel'}>
                            {actionButton}
                        <div/>
                    </div>
                    }

                    {isProgressLineVisible && <ProgressLine size={"s"}/>}

                </div>
                <div className={'sidebar-content'}>
                    {content}
                </div>
            </Sidebar.Content>
            <Sidebar.Actions>
                <div className={'sidebar-button'}>
                    <Button
                        size="m"
                        label={t('save')}
                        width="default"
                        onClick={(e: any) => onSave()}
                        iconLeft={IconSave}
                        iconSize={"xs"}
                    />
                </div>
                <div className={'sidebar-button'}>
                    <Button
                        size="m"
                        view="secondary"
                        label={t('cancel')}
                        width="default"
                        onClick={() => onCancel()}
                        iconLeft={IconBlock}
                        iconSize={"xs"}
                    />
                </div>
                {onDelete &&
                    <div className={'sidebar-button-delete'}>
                        <Button
                            size="m"
                            view="secondary"
                            onlyIcon
                            width="default"
                            onClick={() => onDelete()}
                            iconLeft={IconDelete}
                            iconSize={"xs"}
                        />
                    </div>}
            </Sidebar.Actions>
        </Sidebar>
    );

}

export default withTranslation()(SideBar);