import './Check.css'
import * as React from 'react';
import {useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next'
import {Button} from "nx-design/Button";
import {IconCheck} from "nx-design/IconCheck";
import {pathItem} from "../../startPage/helpers";
import {onCellClick, Table} from "nx-design/Table";
import resource from "../../../Resource";
import {classExtension} from "../../classExtension";
import {Typography} from "nx-design/Typography";
import {IconCheckC} from "nx-design/IconCheckC";

export type PropCheck = {
    activeObject: pathItem;
    context: any;
    setCheckRow: (value: boolean) => void;
};

function Check (props: PropCheck) {

    const {
        activeObject,
        context,
        setCheckRow,
    } = props;

    const [query, setQuery] = useState<any>(undefined);
    const [activeRow, setActiveRow] = React.useState<string>();

    const { t } = useTranslation();



    // useEffect(() => {
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // },[]);

    const validationButtonOnClick = () => {
        setActiveRow(undefined)
        if (activeObject) {
            resource.call(activeObject, 'validate').then(json => {
                setQuery(json)
                if (json.problems.length === 0) { setCheckRow(false) }
                else { setCheckRow(true) }
            })
        }
    }

    const createSuccessField = () => {
        return (
            <div className={"check-success"}>
                <IconCheckC view={"success"} size={"l"}/>
                <Typography>{t('success')} </Typography>
            </div>
        )
    }

    const columns: any[] = [
        {
            title: t('context'),
            accessor: 'context',
        },
        {
            title: t('constraint'),
            accessor: 'constraint',
        },
        {
            title: t('isCritique'),
            accessor: 'isCritique',
        },
        {
            title: 'id',
            accessor: 'id',
            hidden: true
        },
        {
            title: 'e_id',
            accessor: 'e_id',
            hidden: true
        },
        {
            title: '_type_',
            accessor: '_type_',
            hidden: true
        },
        {
            title: t('message'),
            accessor: 'message',
            renderCell: (row: any) => {
                return (
                    <span className={'check-message'}>
                        {row.message}
                    </span>
                );
            }
        }
    ]

    const getDataSource = () => {
        return query.problems.map((p: any, index: number) => {
            return {
                context: p.context,
                constraint: p.constraint,
                isCritique: p.isCritique.toString(),
                message: p.message,
                e_id: p.e_id,
                id: index,
                _type_: p._type_ }
        })
    }

    const handleClickRow = ({ id }: { id?: string }): void => {
        setActiveRow(id);
    };

    // @ts-ignore
    const selectRow: onCellClick = ({ e, type, rowId, columnIdx, ref }) => {
        if (type === 'click' && rowId !== undefined && context.selectNodeInDesigner) {
            const checkRow = query.problems.find((p: any, index: number) => index === +rowId)
            const entity = classExtension.nodes(context.entity).find((n: any) => n.e_id === checkRow.e_id)
            if (entity !== undefined) {
                context.selectNodeInDesigner(entity)
            }
        }
    };

    return (
        <div className={'check'}
             style={{['--check-height' as string]: `${query !== undefined && query.problems !== undefined && query.problems.length > 4 ? 'calc(100% - 47px)' : 'unset'}`}}
        >
            <div className={'check-button'}>
                <Button
                    view="secondary"
                    iconLeft={IconCheck}
                    iconSize={"xs"}
                    label={t('runtransformation')}
                    onClick={() => {validationButtonOnClick()}}
                />
            </div>
            {query ?
                query.result ?
                    createSuccessField() :
                    <Table
                        columns={columns}
                        rows={getDataSource()}
                        separateRows={true}
                        size={"m"}
                        activeRow={{ id: activeRow, onChange: handleClickRow }}
                        onCellClick={selectRow}
                        emptyRowsPlaceholder={t('nodata')}
                    />
                :
                null
            }
        </div>
    );
}

export default withTranslation()(Check);
