import './RightMenu.css'
import * as React from 'react';
import {useTranslation, withTranslation} from "react-i18next"
import {Typography} from "nx-design/Typography";
import {pathItem, recursionItems, renderIcon, splitName, ViewProp, viewPropDefault} from "../helpers";
import {Button} from "nx-design/Button";

type PropsRightMenu = {
    pathHistory: pathItem[][];
    push: (path: string) => void;
    view: ViewProp;
    animationOn: boolean;
};

function RightMenu (props: PropsRightMenu) {

    const {
        pathHistory,
        push,
        view,
        animationOn
    } = props;

    const { t } = useTranslation();

    const classNameAnimationRightMenu =
        animationOn && view === viewPropDefault ?
            "RightMenuCompact" :
            animationOn && view !== viewPropDefault ?
                "RightMenuExtended" : "";

    return (
        <div className={`${classNameAnimationRightMenu} right-menu`}>
            <Typography className={'right-menu-typo-label'}>{t('lastviewed')}</Typography>
            <div className={view === viewPropDefault ? 'right-menu-menu' : 'right-menu-menu-extended'}>
                {pathHistory ? pathHistory.map((p: pathItem[], index) => {
                    const m: pathItem = p[p.length - 1]
                    return <div key={m.name + index}>
                        <Button
                            view={"clear"}
                            name={JSON.stringify(p)}
                            onClick={(e: any) => push(JSON.parse(e.currentTarget.name))}
                            iconLeft={renderIcon(recursionItems().filter((r:any) => r.label === m._type_)[0], "s", "right-menu-icon")}
                            label={
                                <Typography className={'right-menu-typo-place'}>{t(splitName(m._type_))}
                                    <Typography className={'right-menu-typo'}>{m.name}</Typography>
                                </Typography>
                            }
                        />
                    </div>
                }) : []}
            </div>
        </div>
    );

}

export default withTranslation()(RightMenu);
