import "./ButtonView.css"
import * as React from 'react';
import {useRef} from 'react';
import {withTranslation} from "react-i18next"
import {Button} from "nx-design/Button";
import {Typography} from "nx-design/Typography";
import {getItemsMenu, Item, renderIcon, ViewProp, viewPropDefault} from "../helpers";

type PropsButtonView = {
    keyName: string;
    label: string;
    viewPage: ViewProp;
    menu: Item;
    typeName: string | undefined;
    push: (path: string) => void;
};

function ButtonView (props: PropsButtonView) {

    const {
        keyName,
        label,
        viewPage,
        menu,
        typeName,
        push
    } = props;

    const ref = useRef(null);

    const onChange = (e: any) => {
        let path = [];
        if (e.currentTarget.name === "ui3.Module") {
            path.push({
                    _type_: e.currentTarget.name,
                    name: e.currentTarget.id
                },
                {
                    _type_: menu.subMenu !== undefined && menu.subMenu[0].type,
                    name: menu.subMenu !== undefined && menu.subMenu[0].key
                })
        }
        else {

            let items_ = getItemsMenu(e.currentTarget.id);

            path = [
                {name: "Datagram", _type_: "ui3.Application"},
                {name: items_ !== undefined && items_.key, _type_: items_ !== undefined && items_.type},
                {name: e.currentTarget.id, _type_: e.currentTarget.name}
            ];
        }

        push(JSON.parse(JSON.stringify(path)))
    }

    return (
        <Button
            className={`${viewPage === viewPropDefault ? "button-view button-view-compact button-view-compact-media" : "button-view button-view-extended button-view-extended-media" }`}
            id={keyName}
            iconLeft={renderIcon(menu, viewPage === viewPropDefault ? "l" : "m" , viewPage === viewPropDefault ? "button-view-icon-compact" : "button-view-icon-extended")}
            label={
                <Typography
                    className="button-view-typography"
                    size={viewPage === viewPropDefault ? "xl" : "l" }
                >
                    {label}
                </Typography>
            }
            view={"ghost"}
            name={typeName}
            onClick={ (e: any) => onChange(e)}
            ref={ref}
        />
    );

}

export default withTranslation()(ButtonView);
