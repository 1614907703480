import './Lineage.css'
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next'
import {pathItem} from "../../startPage/helpers";
import resource from "../../../Resource";
import {Tree} from "antd";
import {classExtension} from "../../classExtension";
import {Typography} from "nx-design/Typography";
import {IconInbox} from "nx-design/IconInbox";

export type PropLinage = {
    activeObject: pathItem;
    context: any;
};

const TreeNode = Tree.TreeNode

function Linage (props: PropLinage) {

    const {
        activeObject,
        context
    } = props;

    const [selectedNode, setSelectedNode] = useState<any>(null);
    const [newSelectedNode, setNewSelectedNode] = useState<any>(null);
    const [selectedNodeData, setSelectedNodeData] = useState<any>(null);
    const [valueList, setValueList] = useState<any[]>([]);
    const [selectedLeaf, setSelectedLeaf] = useState<any>(null);
    const [selectedListData, setSelectedListData] = useState<any[]>([]);
    const [selectedPortKey, setSelectedPortKey] = useState<any[]>([]);

    const [depKeys, setDepKeys] = useState<any[]>([]);
    const [folKeys, setFolKeys] = useState<any[]>([]);

    const { t } = useTranslation()

    useEffect(() => {
        if (context.selectedNode) {
            if (context.selectedNode !== selectedNode) {
                setNewSelectedNode(context.selectedNode)
                setSelectedLeaf(null)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[context.selectedNode]);

    useEffect(() => {
        if (selectedNode !== newSelectedNode) {
            setSelectedNode(newSelectedNode)
            getNodeDependencies(newSelectedNode)
            setSelectedPortKey([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedNode, newSelectedNode]);

    const getNodeDependencies = (node: any) => {
        if (node) {
            if (node.transformation) {
                resource.call({
                    _type_: "etl.Transformation",
                    e_id: node.transformation.e_id,
                    node_name: node.name,
                }, "nodeDependencies", {}).then(data => {
                    setSelectedNodeData(data)
                    getList(data)
                })
            }
        }
    }

    const getList = (selectedNodeData: any) => {
        let valueList = []
        if (selectedNodeData) {
            if (selectedNodeData.dependencies) {
                valueList = selectedNodeData.dependencies.map((el: any, index: number) =>
                    <TreeNode key={el.fieldName + index} title={el.fieldName + ': ' + el.fieldType} data={el.fieldName}></TreeNode>
                )
            } else {
                if (selectedNodeData.followers) {
                    valueList = selectedNodeData.followers.map((el: any, index: number) =>
                        <TreeNode key={el.fieldName + index} title={el.fieldName + ': ' + el.fieldType} data={el.fieldName}></TreeNode>
                    )
                }
            }
        }
        setValueList(valueList)
    }

    const getTreeNode = (el: any, type: any, parent: any) => {
        if (el[type]) {
            if (selectedNode.name !== el.nodeName) {
                var key = parent + el.nodeName + el.fieldName
                type === 'dependencies' ? depKeys.push(key) : folKeys.push(key)
                return (
                    <TreeNode key={parent + el.nodeName + el.fieldName} title={el.nodeName + '.' + el.fieldName + ':' + el.fieldType} fieldName={el.fieldName} data={el.nodeName}>
                        {el[type].map((f: any) => getTreeNode(f, type, parent))}
                    </TreeNode>
                )
            } else {
                return el[type].map((f: any) => getTreeNode(f, type, parent))
            }
        }
    }

    const portInTreeOnClick = (event: any) => {
        if (context.selectNodeInDesigner) {
            const entity = classExtension.nodes(context.entity).find((n: any) => n.name === event.node.props.data)
            context.selectNodeInDesigner(entity)
            setSelectedLeaf(event.node.props.fieldName)
        }
    }

    const portOutTreeOnClick = (event: any) => {
        if (context.selectNodeInDesigner) {
            const entity = classExtension.nodes(context.entity).find((n: any) => n.name === event.node.props.data)
            context.selectNodeInDesigner(entity)
            setSelectedLeaf(event.node.props.fieldName)
        }
    }

    const treeListOnClick = (event: any) => {
        setDepKeys([])
        setFolKeys([])
        setSelectedLeaf(event.node.props.data)
        setSelectedListData(event.node.props.data)
    }

    const createDependenciesView = () => {
        // const selectedLeaf_ = selectedLeaf || valueList[0] ? valueList[0].props.data : null
        const selectedTreeNode = valueList.length > 0 && selectedLeaf ? valueList.find((v: any) => v.props.data === selectedLeaf) : [];

        const dependencies = selectedNodeData ?
            selectedNodeData.dependencies.filter((f: any) => f.fieldName === selectedLeaf).map((dep: any) => getTreeNode(dep, 'dependencies', selectedListData + 'in_'))
            : []

        const followers = selectedNodeData ?
            selectedNodeData.followers.filter((f: any) => f.fieldName === selectedLeaf).map((fl: any) => getTreeNode(fl, 'followers', selectedListData + 'out_'))
             : []

        return <div className={'lineage-success'}>
            <div className={'lineage-valueList'}>
                <div className={'lineage-valueList-header'}>
                    <Typography size={"l"} weight={"medium"}>{t('fields')}</Typography>
                </div>
                <div className={'lineage-valueList-content'}>
                    {valueList.length !== 0 ?
                        <Tree
                            onSelect={(key, event) => treeListOnClick(event)}
                            selectedKeys={selectedTreeNode ? [selectedTreeNode.key] : []}
                        >
                            {valueList}
                        </Tree>
                        :
                        <div className={'lineage-valueList-content-nodata'}>{t('nodata')}</div>
                    }
                </div>
            </div>
            <div className={'lineage-tabs'}>
                <div className={'lineage-tabs-inport'}>
                    <div className={'lineage-valueList-header'}>
                        <Typography size={"l"} weight={"medium"}>{t('inport')}</Typography>
                    </div>
                    <div className={'lineage-tabs-tree'}>
                        {dependencies.length !== 0 && dependencies[0].length !== 0 ?
                            <Tree expandedKeys={depKeys} selectedKeys={selectedPortKey} onSelect={(key, event) => portInTreeOnClick(event)}>
                                {dependencies}
                            </Tree> :
                            <div className={'lineage-tabs-nodata'}>{t('nodata')}</div>
                        }
                    </div>
                </div>
                <div className={'lineage-tabs-outport'}>
                    <div className={'lineage-valueList-header'}>
                        <Typography size={"l"} weight={"medium"}>{t('outport')}</Typography>
                    </div>
                    <div className={'lineage-tabs-tree'}>
                        {followers.length !== 0 && followers[0].length !== 0 ?
                            <Tree expandedKeys={folKeys} selectedKeys={selectedPortKey} onSelect={(key, event) => portOutTreeOnClick(event)}>
                                {followers}
                            </Tree> :
                            <div className={'lineage-tabs-nodata'}>{t('nodata')}</div>
                        }
                    </div>
                </div>
            </div>
        </div>
    }

    const createNoObjectView = () =>
        <div className={'lineage-noobject'}>
            <IconInbox size={"m"} view={"secondary"}/>
            <Typography className={'lineage-noobject-typo'} size={"m"}>{t('selectItem')}</Typography>
        </div>


    return (
        <div className={'lineage'}>
            {
                activeObject && selectedNodeData ?
                    createDependenciesView() :
                    createNoObjectView()
            }
        </div>

    );

}

export default withTranslation()(Linage);
