import './RightSideBar.css'
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next'
import {getClassDef} from "../../../model";
import {ButtonTooltip, pathItem, splitName} from "../../startPage/helpers";
import update from "immutability-helper";
import _ from "lodash";
import {modifyBeforeSave} from "../../../utils/meta";
import resource from "../../../Resource";
import FieldList from "./FieldList";
import SideBar, {TypeSidebarProp, typeSidebarProp} from "../../sideBar/SideBar";
import {IconDelete} from "nx-design/IconDelete";
import {IconEllipsisV} from "nx-design/IconEllipsisV";
import {ContextMenu, ContextMenuItemDefault} from "nx-design/ContextMenu";
import {IconUpload} from "nx-design/IconUpload";
import {IconDownload} from "nx-design/IconDownload";
import {IconCode} from "nx-design/IconCode";
import {IconTree} from "nx-design/IconTree";
import {IconPlay} from "nx-design/IconPlay";
import {getIconRightSideBar} from "./helpers";
import {IconComponent} from "nx-design/__internal__/cjs-src/icons/_Icon/Icon";
import {Tooltip} from "antd";
import {IconHelpCircle} from "nx-design/IconHelpCircle";

export type PropRightSideBarLite = {
    activeObject: pathItem;
    typeSidebar: TypeSidebarProp,
    stateSidebar: boolean;
    onCloseSideBar: (clickSave: boolean) => void;
    context: any;
    openObject: pathItem;
};

function RightSideBarLite (props: PropRightSideBarLite) {

    const {
        activeObject,
        stateSidebar,
        typeSidebar,
        onCloseSideBar,
        context,
        openObject
    } = props;

    const { t } = useTranslation();

    const [isProgressLineVisible, setProgressLineVisible] = useState(false);
    const [entity, setEntity] = useState<any>(openObject);
    const [eDocumentation, setEDocumentation] = useState<any>();
    const classDef = getClassDef(entity._type_);
    const [itemActions, setItemActions] = useState<ContextMenuItemDefault[]>([]);
    const [isOpenActions, setIsOpenActions] = useState(false);
    const refActions = useRef(null);

    useEffect(() => {
        getEntity(openObject._type_, openObject.e_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[openObject]);

    const getSubItemActions = (label: string, subItemActions: any[], icon: IconComponent, groupId: number, eDocumentation: any) => {
        if (subItemActions.length > 0) {
            const Icon = icon;
            const item: ContextMenuItemDefault = {
                label: label,
                key: label,
                leftSide: <Icon size={"xs"}/>,
                groupId: groupId,
                subMenu: subItemActions.map((action: any) => {
                    let doc = null;
                    if (eDocumentation !== undefined && eDocumentation[action.name] !== undefined) {doc = eDocumentation[action.name];}
                    const rightIcon = doc ? <Tooltip title={doc.split('. ').map((d: any) => {return <p>{d}</p>})}><IconHelpCircle size={"xs"} view={"secondary"}/></Tooltip> : <></>;
                    return {
                        label: t(classDef.successors + '.ops.' + action.name + '.caption', { ns: 'classes' }),
                        key: action.name,
                        onClick: (params: any) => {onActionClick(params)},
                        rightSide: rightIcon,
                        withSubMenu: true,
                        leftSide: getIconRightSideBar(action.name) || <div style={{marginLeft: '15px'}}/>,
                    }
                })
            }
            return item;
        }
        else {
            const item: ContextMenuItemDefault = {
                label: t(activeObject._type_ + '.ops.' + subItemActions[0].name + '.caption', { ns: 'classes' }),
                key: subItemActions[0].name,
                leftSide: getIconRightSideBar(subItemActions[0].name) || <div style={{marginLeft: '15px'}}/>,
                groupId: groupId,
                onClick: (params: any) => {onActionClick(params)},
            }
            return item;
        }
    }

    const getItemActions = (eDocumentation: any) => {
        const itemActions: ContextMenuItemDefault[] = [];
        if ( classDef && classDef.actions && classDef.actions.length > 0 ) {
            const importMenu = classDef.actions.filter((f: any) => f.name.toLowerCase().includes('import') || f.name.toLowerCase().includes('upload')  );
            const exportMenu = classDef.actions.filter((f: any) => f.name.toLowerCase().includes('export') || f.name.toLowerCase().includes('download') );
            const svnMenu = classDef.actions.filter((f: any) => f.name.toLowerCase().includes('svn') );
            const testMenu = classDef.actions.filter((f: any) => f.name.toLowerCase().includes('test') );
            const runMenu = classDef.actions.filter((f: any) => f.name.toLowerCase().includes('run') );

            const otherMenu = classDef.actions.filter((f: any) =>
                !f.name.toLowerCase().includes('import') &&
                !f.name.toLowerCase().includes('upload') &&
                !f.name.toLowerCase().includes('export') &&
                !f.name.toLowerCase().includes('download') &&
                !f.name.toLowerCase().includes('svn') &&
                !f.name.toLowerCase().includes('test') &&
                !f.name.toLowerCase().includes('run')
            );

            if (importMenu.length !== 0) {itemActions.push(getSubItemActions(t('upload'), importMenu, IconUpload, 1, eDocumentation));}
            if (exportMenu.length !== 0) {itemActions.push(getSubItemActions(t('download'), exportMenu, IconDownload, 1, eDocumentation));}
            if (svnMenu.length !== 0) {itemActions.push(getSubItemActions('SVN', svnMenu, IconCode, 2, eDocumentation));}
            if (testMenu.length !== 0) {itemActions.push(getSubItemActions(t('tests'), testMenu, IconTree, 3, eDocumentation));}
            if (runMenu.length !== 0) {itemActions.push(getSubItemActions(t('run'), runMenu, IconPlay, 4, eDocumentation));}

            otherMenu.forEach((action: any) => {
                let doc = null;
                if (eDocumentation !== undefined && eDocumentation[action.name] !== undefined) {doc = eDocumentation[action.name];}
                const rightIcon = doc ? <><Tooltip title={doc.split('. ').map((d: any) => {return <p>{d}</p>})}><IconHelpCircle size={"xs"} view={"secondary"}/></Tooltip><div style={{marginLeft: '15px'}}/></> : <div style={{marginLeft: '15px'}}/>;

                const item: ContextMenuItemDefault =
                    {
                        label: t(classDef.successors + '.ops.' + action.name + '.caption', { ns: 'classes' }),
                        key: action.name,
                        leftSide: getIconRightSideBar(action.name) || <div style={{marginLeft: '15px'}}/>,
                        rightSide: rightIcon,
                        onClick: (params: any) => {onActionClick(params)},
                        groupId: 5,
                    }
                itemActions.push(item);
            })

            setItemActions(itemActions);
        }
    }

    const onActionClick = (params: any) => {
        const actionIndex = classDef.actions.findIndex((a: any) => a.name === params.item.key)
        if (actionIndex >= 0) {
            const action = classDef.actions[actionIndex]
            if (!action.parameters || action.parameters.length === 0) {
                setProgressLineVisible(true);
                const entity_ = {
                    e_id: Number(entity.e_id),
                    name: entity.name,
                    _type_: entity._type_
                }
                if (action.file) {
                    resource.download(entity_, action.name, {export:true}, `${entity.name}.zip`);
                    setProgressLineVisible(false);
                }
                else {
                    resource.call(entity_, action.name).then(json => {
                        window.dispatchEvent(new Event(action.name));
                        resource.logInfo(JSON.stringify(json, null, 2));
                        setProgressLineVisible(false);
                        context.updateContext({ updateLeftLayoutMenu: true })
                    }).catch(() => setProgressLineVisible(false))
                }
            }
        }
        return
    };

    const getEntity = (_type_: string, e_id: any) => {
        if (typeSidebar === typeSidebarProp[0]) {
            if (_type_ && e_id) {
                return resource.getEntity(_type_, e_id).then(entity => {
                    setEntity(entity)
                    setEDocumentation(entity.eDocumentation)
                    getItemActions(entity.eDocumentation);
                })
            }
        }
        else if (typeSidebar === typeSidebarProp[1] && entity.name === "") {
            let entityNew = {_type_: _type_, name: "", e_id: undefined}
            const field = classDef.fields.find((field: any) => field.entityType === activeObject._type_)
            if (field) {
                // @ts-ignore
                entityNew[field.name] = activeObject;
            }
            setEntity(entityNew)
            resource.getEDocumentation(entityNew._type_).then(json => {
                setEDocumentation(json.eDocumentation)
                getItemActions(json.eDocumentation);
            })
        }

        return Promise.resolve(null);
    }

    const deleteProp = (value: any, propName: any, parent: any, isUseToRestore?: any, removeCondition?: any, ready?: any) => {
        if(!ready) {
            ready = []
        }
        if (typeof value !== "object") {
            if (!Array.isArray(value)) {
                return value
            }
        }
        if (!Array.isArray(value)) {
            if (value) {
                if (value.hasOwnProperty(propName)) {
                    delete value[propName]
                }
                if(ready.indexOf(value) === -1) {
                    ready.push(value)
                    Object.entries(value).forEach((e) => {
                        deleteProp(e[1], propName, parent + "." + e[0], isUseToRestore, removeCondition, ready)
                    })
                }
            }
        } else {
            if(ready.indexOf(value) === -1) {
                ready.push(value)
                value.forEach((node, index) => {
                    deleteProp(node, propName, parent + '[' + index + ']', isUseToRestore, removeCondition, ready)
                })
            }
        }
        return value
    }

    const removeTransient = (entity: any) => {
        return _.cloneDeepWith(entity, (value) => deleteProp(value, "transient", "entity"))
    }

    const save = () => {
        setProgressLineVisible(true);
        let e = _.cloneDeep(entity);
        e = removeTransient(e)

        const afterSave = modifyBeforeSave(e)
        let prom = resource.saveEntity(e)
        prom = afterSave(prom)

        prom.then(entity => {
            setEntity(entity)
            setProgressLineVisible(false);
            context.updateContext({ updateLeftLayoutMenu: true })
        }).catch( error => {
            setProgressLineVisible(false)
        } )
    }

    const onSave = () => {
        save();
    };

    const onCancel = () => {
        setProgressLineVisible(true);
        if (activeObject._type_) {
            // @ts-ignore
            getEntity(activeObject._type_, activeObject.e_id).then(() => {
                setTimeout(() => {setProgressLineVisible(false)}, 300);
            })
        }
    };

    const onDelete = () => {
        resource.deleteEntity(entity).then(entity => {
            onCloseSideBar(false);
            context.updateContext({ updateLeftLayoutMenu: true })
            setEntity(undefined)
        })
    };

    const onClose = () => {
        context.updateContext({ entity });
        onCloseSideBar(false);
    };

    return (
        <SideBar
            stateSidebar = {stateSidebar}
            label = {t(typeSidebar) + ": " + t(splitName(entity._type_)) + " " + entity.name}
            actionButton = {<>
                <ButtonTooltip
                    onlyIcon
                    iconLeft={IconDelete}
                    tooltipProps={{ content: t('delete'), direction: 'downCenter' }}
                    view={"clear"}
                    onClick={() => onDelete()}
                    size={"xs"}
                />
                <div className={'sidebar-line'}/>
                {
                    itemActions.length !== 0 &&
                    <div className={'sidebar-action-button'}>
                        <ButtonTooltip
                            onlyIcon
                            iconLeft={IconEllipsisV}
                            tooltipProps={{ content: t('evs.Rule.attrs.actions.caption', {ns: ['classes']}), direction: 'downCenter' }}
                            view={"clear"}
                            size={"xs"}
                            onClick={() => setIsOpenActions(!isOpenActions)}
                            ref={refActions}
                        />
                        <ContextMenu
                            isOpen={isOpenActions}
                            items={itemActions}
                            anchorRef={refActions}
                            direction="downCenter"
                            subMenuDirection="rightStartUp"
                            onClickOutside={() => setIsOpenActions(false)}
                            size={"s"}
                            offset={"m"}
                            theme={"secondary"}
                        />
                    </div>
                }
            </>}
            content = {<>
                {classDef.fields !== undefined && entity !== undefined &&
                    <FieldList
                        fields={classDef.fields}
                        eDocumentation={eDocumentation}
                        entity={entity}
                        showHidden={true}
                        readOnly={false}
                        updateEntity={(value: any) => setEntity( update(entity, { $merge: value })  )}
                        t={t}
                    />
                }
            </>}
            onClose = {() => onClose()}
            onDelete = {() => onDelete()}
            onSave = {() => onSave()}
            onCancel = {() => onCancel()}
            isProgressLineVisible={isProgressLineVisible}
        />
    );

}

export default withTranslation()(RightSideBarLite);