import './GridLayout.css'
import * as React from 'react';
import {useEffect, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next'
import {buttonAddPropType, LeftSide, onCellClick, RightSide, Table} from "nx-design/Table";
import _ from "lodash";
import {getClassDef} from "../../../model";
import resource from "../../../Resource";
import {pathItem} from "../../startPage/helpers";
import {ItemRightSide} from "nx-design/__internal__/cjs-src/components/Table/RightSide/RightSide";
import {HeaderSideProps} from "nx-design/__internal__/cjs-src/components/Table/headerMenu";
import {ContextMenuItemDefault} from "nx-design/ContextMenu";
import {Button} from "nx-design/Button";
import {IconCopy} from "nx-design/IconCopy";
import {IconDelete} from "nx-design/IconDelete";
import RightSideBar from "../rightSideBar/RightSideBar";
import {IconSettingEdit} from "nx-design/IconSettingEdit";
import {TypeSidebarProp, typeSidebarProp, typeSidebarPropDefault} from "../../sideBar/SideBar";

export type PropGridLayout = {
    activeObject: pathItem;
    push: (path: string) => void;
    path: any[];
    context: any;
    onSelectObject: (arg: object) => void;
    isSidebarOpenOther: boolean;
    closeOther: () => void;
};

function GridLayout (props: PropGridLayout) {

    const {
        activeObject,
        push,
        path,
        context,
        onSelectObject,
        isSidebarOpenOther,
        closeOther
    } = props;

    const { t } = useTranslation();

    const [rows, setRows] = useState<any[]>([]);
    const [column, setColumn] = useState<any[]>([]);
    const [lineVisible, setLineVisible] = useState<boolean>(false);
    const [storageId, setStorageId] = useState<string>("dd_" + activeObject.name);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const [typeSidebar, setTypeSidebar] = useState<TypeSidebarProp>(typeSidebarPropDefault);

    const readOnlyMode = _.get(getClassDef(activeObject.name), 'gridReadOnly');

    const load = (storageId: string, translationColLength: number) => {
        if (storageId) {
            const value = localStorage.getItem(storageId)
            if (value && JSON.parse(value).length - 1 === translationColLength) {
                return JSON.parse(value)
            }
        }
        return undefined;
    }

    const onDelete = (e: any) => {
        if (isSidebarOpen) {onCloseSideBar(false)}

        const delEntity = JSON.parse(e.currentTarget.id);
        resource.deleteEntity(delEntity).then(entity => {
            if (path.length > 3) {
                path.pop();
                push(JSON.parse(JSON.stringify(path)));
            }
            refreshTable();
        })
    };

    const loadObjectList = (object: any, newStorageId: string) => {
        //create columns
        const col = _.get(getClassDef(object.name), 'columns', [
            {
                title: "Name",
                accessor: "name",
                sortable: true,
                hidden: false
            }]).map((c: any) => {
            return {
                title: c.Header,
                accessor: c.accessor,
                sortable: true,
                hidden: !c.show
            }
        });

        col.push(
            {
                title: 'id',
                accessor: 'id',
                sortable: true,
                hidden: true
            },
            {
                title: 'e_id',
                accessor: 'e_id',
                sortable: true,
                hidden: true
            },
            {
                title: 'e_type',
                accessor: 'e_type',
                sortable: true,
                hidden: true
            }
        )
        //translation of titles
        const translationCol = col.map((c: any) => Object.assign({}, c, {title: t(c.title, {ns: ['classes', 'common']})}))

        const selected = load(newStorageId, translationCol.length) || translationCol;

        if (selected[0].accessor === "actions") {selected.shift()}
        !readOnlyMode && selected.unshift(
            {
                title: t('evs.Rule.attrs.actions.caption', {ns: ['classes', 'common']}),
                accessor: 'actions',
                width: 115,
                renderCell: (row: any) => {
                    return (
                        <div className={'actions-column'}>
                            <Button
                                id={JSON.stringify({e_id: row.e_id, name: row.name, _type_: row.e_type})}
                                name={row.name}
                                size="xs"
                                view="clear"
                                iconLeft={IconSettingEdit}
                                onlyIcon
                                onClick={(e: any) => {onEdit(e)}}
                            />
                            <Button
                                id={JSON.stringify({e_id: row.e_id, name: row.name, _type_: row.e_type})}
                                name={row.name}
                                size="xs"
                                view="clear"
                                iconLeft={IconCopy}
                                onlyIcon
                                onClick={(e: any) => {onCopy(e)}}
                            />
                            <Button
                                id={JSON.stringify({e_id: row.e_id, name: row.name, _type_: row.e_type})}
                                name={row.name}
                                size="xs"
                                view="clear"
                                iconLeft={IconDelete}
                                onlyIcon
                                onClick={(e: any) => {onDelete(e)}}
                            />
                        </div>
                    );
                }

            }
        )

        setColumn(selected)

        //create rows
        resource.getList(object.name)
            .then(data => {

                const newData = data.map( (d: any, index: any) => {

                    let singleObj = {};
                    col.forEach(function(m: any) {
                        if (m.accessor === 'id') {
                            // @ts-ignore
                            singleObj[m.accessor] = index;
                        }
                        else if (m.accessor === 'e_type') {
                            // @ts-ignore
                            singleObj[m.accessor] = d._type_;
                        }
                        else {
                            if (m.accessor.includes('.') && d[m.accessor.split('.')[0]] !== undefined) {
                                // @ts-ignore
                                singleObj[m.accessor] = d[m.accessor.split('.')[0]][m.accessor.split('.')[1]];
                            }
                            else {
                                // @ts-ignore
                                singleObj[m.accessor] = d[m.accessor];
                            }
                        }
                    });
                    return singleObj;

                })
                setRows(newData);
                setLineVisible(false);
            }).catch((e: any) => setLineVisible(false))
    }

    const refreshTable = () => {
        if (activeObject._type_ === "ecore.EClass" ) {
            const newStorageId = "dd_" + activeObject.name;
            setStorageId(newStorageId);
            loadObjectList(activeObject, newStorageId)
        }
        else if (activeObject._type_ === "ui3.Module") {
            setColumn([]);
        }
        else {
            const newStorageId = "dd_" + path[2].name;
            setStorageId(newStorageId);
            loadObjectList(path[2], newStorageId)
        }
    }

    useEffect(() => {
        refreshTable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeObject]);

    useEffect(() => {
        if (isSidebarOpen) {onCloseSideBar(false)}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[path[2] && path[2]._type_]);

    useEffect(() => {
        if (isSidebarOpenOther) {onCloseSideBar(false)}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isSidebarOpenOther]);

    useEffect(() => {
        if (context.entity !== undefined && context.entity.e_id !== undefined) {
            setTypeSidebar(typeSidebarProp[0])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[context.entity]);

    const store = (value: any[]) => {
        if (storageId) {
            localStorage.setItem(storageId, JSON.stringify(value))
        }
    }

    const overrideHiddenColumns = (newCheckboxGroupValue: ItemRightSide[] | null) => {
        const copyColumns = column.map((column) => {
            const newColumn = { ...column };

            const update: ItemRightSide | undefined = newCheckboxGroupValue === null ? undefined :
                newCheckboxGroupValue.find( (v:ItemRightSide) => newColumn.title === v.title)

            if (update === undefined) {
                if (newColumn.hidden !== undefined && newColumn.hidden === false) {newColumn.hidden = !column.hidden}
                else if (newColumn.hidden === undefined) {newColumn.hidden = true}
            }

            if (update !== undefined) {
                if (newColumn.hidden === true) {newColumn.hidden = !column.hidden}
                else if (newColumn.hidden === undefined) {newColumn.hidden = false}
            }

            return newColumn
        });
        setColumn(copyColumns);
        if (copyColumns.length !== 0) {store(copyColumns)}
    }

    const onClickButtonAddColumn = (event: any) => {
        setIsSidebarOpen(true);
        closeOther()
        setTypeSidebar(typeSidebarProp[1]);
    }

    const onClickButtonRefresh = () => {
        setLineVisible(true);
        refreshTable();
    }

    const rightSide: HeaderSideProps = {
        name: RightSide,
        props: {
            nameApplyHiddenColumn: t('close'),
            nameButtonColumnSettings: t('hideshowcolumns'),
            overrideHiddenColumns: (newCheckboxGroupValue: ItemRightSide[] | null) => {overrideHiddenColumns(newCheckboxGroupValue)}
        }
    };

    const items: ContextMenuItemDefault[] = activeObject._type_ !== "ui3.Module" ?
        getClassDef(activeObject._type_ === "ecore.EClass" ? activeObject.name : activeObject._type_).successors
        .map((item: ContextMenuItemDefault) => {
            return {
                label: t(`${item}.caption`, { ns: 'classes' }),
                key: item,
                onClick: (e: any) => { onClickButtonAddColumn(e) }
            }
        }) : [];

    const leftSide: HeaderSideProps =
        {
            name: LeftSide,
            props: {
                nameButtonAddColumn: t('new'),
                nameButtonRefresh: t('refresh'),
                onClickButtonRefresh: () => {onClickButtonRefresh()},
                buttonAddItems: items,
                buttonAddType: buttonAddPropType[0],
                onClickButtonAdd: (event: any) => {onClickButtonAddColumn(event)},
                buttonAddColumnVisible: true
            }
        };

    // @ts-ignore
    const selectRow: onCellClick = ({ e, type, rowId, columnIdx, ref }) => {
        if (type === 'click' && columnIdx !== 0 && rowId !== undefined) {
            updateSelectObject(rowId);
        }
    };

    const updateSelectObject = (rowId: any) => {
        context.updateContext({ openDesigner: true })
        if (path.length > 3) {path.pop()}
        path.push({_type_: path[2].name, name: rows[rowId].name, e_id: rows[rowId].e_id});
        push(JSON.parse(JSON.stringify(path)))
    };

    const onCloseSideBar = (clickSave: boolean) => {
        setIsSidebarOpen(clickSave);
    };

    const onCopy = (e: any) => {
        setLineVisible(true);
        const copyEntity = JSON.parse(e.currentTarget.id);
        if (copyEntity.e_id) {
            const newName = prompt(t('copyof'), `Copy_of_${copyEntity.name}`);
            if (newName) {
                resource.copyEntity(copyEntity._type_, copyEntity.e_id, newName).then(newEntity => {
                    if (path.length > 3) {
                        path.pop();
                    }
                    path.push({_type_: newEntity._type_, name: newEntity.name, e_id: newEntity.e_id});
                    push(JSON.parse(JSON.stringify(path)));
                })
            }
        }
    };

    const onEdit = (e: any) => {
        setTypeSidebar(typeSidebarProp[0]);
        setIsSidebarOpen(true);
        closeOther()

        const editEntity = JSON.parse(e.currentTarget.id);
        if (path.length > 3) {path.pop()}
        path.push({_type_: editEntity._type_, name: editEntity.name, e_id: editEntity.e_id});
        push(JSON.parse(JSON.stringify(path)))
    };

    return (
        <div className={"gridLayout"}>
            {column.length !== 0 && <Table
                columns={column}
                rows={rows}
                rightSide={rightSide}
                leftSide={leftSide}
                stopIsProgressLineVisible={lineVisible}
                withHeaderMenu={true}
                nameResetAllFilters={"Сброить все фильтры"}
                separateRows={true}
                size={"m"}
                isResizable
                stickyColumns={1}
                onCellClick={selectRow}
                emptyRowsPlaceholder={t('nodata')}
            />}
            {isSidebarOpen &&
                <RightSideBar
                    refreshTable={() => onClickButtonRefresh()}
                    activeObject={activeObject}
                    onCloseSideBar={(clickSave: boolean) => onCloseSideBar(clickSave)}
                    stateSidebar={isSidebarOpen}
                    typeSidebar={typeSidebar}
                    onSelectObject={onSelectObject}
                    context={context}
                    path={path}
                    push={push}
                />
            }
        </div>
    );

}

export default withTranslation()(GridLayout);
