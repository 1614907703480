import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Table, Tabs} from 'antd';
import resource from '../../Resource';
import moment from 'moment'
import _ from 'lodash'

class ApplicationInfo extends Component {

    constructor(...args) {
        super(...args);
        this.state = {
            info: {
                cust: {},
                build: {}
            },
            env: {
                activeProfiles: {},
                propertySources: [
                    {
                        name: "server.ports",
                        properties: {}
                    },
                    {
                        name: "servletContextInitParams",
                        properties: {}
                    },
                    {
                        name: "systemProperties",
                        properties: {}
                    },
                    {
                        name: "systemEnvironment",
                        properties: {}
                    },
                    {
                        name: "applicationConfig: [classpath:/application.properties]",
                        properties: {}
                    },
                    {
                        name: "class path resource [license.properties]",
                        properties: {}
                    }
                ]
            },
            metrics: {
                names: []
            },
        }
    }

    componentDidMount() {
        resource.query('/actuator/info').then(info => {
            this.setState({info})
        })
        resource.query('/actuator/metrics').then(metrics => {
            this.setState({metrics})
        })
        resource.query('/actuator/env').then(env => {
            this.setState({env})
        })
    }

    render() {
        const {t} = this.props
        return (
            <Tabs type="editable-card" forceRender={true} className="no-margin" hideAdd style={{backgroundColor: 'white', height: 'calc(100% - 59px)', overflow: 'auto'}}>
                <Tabs.TabPane key={"build"} tab={t("ui3.ApplicationInfo.attrs.build.caption", {ns: "classes"})}
                              closable={false}>
                    <Table showHeader={false} pagination={false} columns={[
                        {dataIndex: "key"},
                        {dataIndex: "value"},
                    ]} dataSource={[
                        {key: "Artifact", value: this.state.info.build.artifact},
                        {key: "Application Group", value: this.state.info.build.group},
                        {key: "Version", value: this.state.info.build.version},
                        {
                            key: "Build Time",
                            value: moment(this.state.info.build.time).format('dddd, DD MMMM YYYY, h:mm:ss')
                        },
                    ]}/>
                </Tabs.TabPane>
                <Tabs.TabPane key={"props"} tab={t("ui3.ApplicationInfo.attrs.props.caption", {ns: "classes"})}
                              closable={false}>
                    <Table showHeader={false} pagination={false} size="small" columns={[
                        {dataIndex: "key", width: "30%"},
                        {dataIndex: "value"},
                    ]} dataSource={
                        _.sortBy(Object.keys(this.state.env.propertySources.find(p => p.name === "systemProperties").properties), key=>key.toLowerCase()).filter(key => key.toLowerCase().indexOf("password") === -1).map(key => {
                            return {key, value: this.state.env.propertySources.find(p => p.name === "systemProperties").properties[key].value}
                        })}/>
                </Tabs.TabPane>
                <Tabs.TabPane key={"env"} tab={t("ui3.ApplicationInfo.attrs.env.caption", {ns: "classes"})} closable={false}>
                    <Table showHeader={false} pagination={false} size="small" columns={[
                        {dataIndex: "key", width: "30%"},
                        {dataIndex: "value"},
                    ]} dataSource={
                        _.sortBy(Object.keys(this.state.env.propertySources.find(p => p.name === "systemEnvironment").properties), key=>key.toLowerCase()).filter(key => key.toLowerCase().indexOf("password") === -1).map(key => {
                            return {key, value: this.state.env.propertySources.find(p => p.name === "systemEnvironment").properties[key].value}
                        })}/>
                </Tabs.TabPane>
                <Tabs.TabPane key={"metrics"} tab={t("ui3.ApplicationInfo.attrs.metrics.caption", {ns: "classes"})} closable={false}>
                    <Table showHeader={false} pagination={false} size="small" columns={[
                        {dataIndex: "key", width: "50%"},
                        {dataIndex: "value"},
                    ]} dataSource={
                        _.sortBy(Object.keys(this.state.metrics.names), key=>key.toLowerCase()).filter(key => key.toLowerCase().indexOf("password") === -1).map(key => {
                            return {key: this.state.metrics.names[key]}
                        })}/>
                </Tabs.TabPane>
                {/*<Tabs.TabPane key={"branches"} tab={t("ui3.ApplicationInfo.attrs.branches.caption", {ns: "classes"})} closable={false}>*/}
                {/*    <BranchesInfo {...this.props}/>*/}
                {/*</Tabs.TabPane>*/}
            </Tabs>
        )
    }

}

export default withTranslation()(ApplicationInfo);
