import React, {Component} from 'react';
import 'react-vis/dist/style.css'
import 'grommet-css';
import 'jointjs/dist/joint.css';
import i18n from './i18n.js';
import ru_RU from 'antd/lib/locale-provider/ru_RU';
import en_US from 'antd/lib/locale-provider/en_US';
import resource from './Resource'
import Login from './components/login/Login';
import StartPage from './components/startPage/StartPage';
import update from 'immutability-helper'
import {Button, LocaleProvider, notification} from 'antd';
import _ from 'lodash'
import {addObjectToPath, buildPath, createHRef} from './utils/encode'
import LayoutPage from "./components/layoutPage/LayoutPage";

const AppContext = React.createContext();

class Ecore extends Component {

    constructor(...args) {
        super(...args);
        this.state = {
            path: [],
            waitMinute: true,
            pathHistory: JSON.parse(localStorage.getItem("pathHistory") || "[]"),
            locale: null,
            stompConnected: false,
            showLogin: false,
            context: {
                updateContext: (context, cb) => {
                    this.setState((state, props) => {
                        return { context: update(state.context, { $merge: context }) }
                    }, cb)
                    return {result: true}
                },
                setCurrentBranch: this.setCurrentBranch,
                branchInfo: {
                    current: null,
                    branches: {
                    }
                },
                openDesigner: false
            },
            searchStr: "",
            splitterPosition: "0%",
            isSSOEnabled: false
        }
    }

    setLang(lang) {
        i18n.changeLanguage(lang)
        this.getLocale()
    }

    getLocale() {
        let localeValue
        i18n.language === 'ru-RU' ? localeValue = ru_RU : localeValue = en_US
        this.setState({ locale: localeValue })
    }

    selectObject(object, options) {
        options = options || {}
        const searchParams = new URLSearchParams(this.props.location.search)
        let path = buildPath(searchParams)
        path = addObjectToPath(path, object, options)
        const args = options.args || {}
        const params = !options.keepSearch ? {} : [...searchParams.keys()].filter(key => key !== "path").reduce((map, key) => {
            return { ...map, [key]: searchParams.get(key) }
        }, {})
        this.push(path, { ...params, ...args })
    }

    onPropsSetOrChange(props) {
        const search = props.location.search
        const urlParams = new URLSearchParams(search)
        const path = buildPath(urlParams)
        const activeObject = path[path.length - 1]
        let pathHistory = this.state.pathHistory
        if (activeObject.e_id) {
            pathHistory = [path.slice(1), ...pathHistory.filter(p => p[p.length - 1].e_id !== activeObject.e_id || p[p.length - 1]._type_ !== activeObject._type_)]
            const delta = pathHistory.length - 10
            if (delta > 0) {
                pathHistory.splice(10, delta)
            }
            localStorage.setItem("pathHistory", JSON.stringify(pathHistory))
        }
        this.setState({ activeObject, searchStr: "", path, urlParams, pathHistory });
    }

    componentWillReceiveProps(nextProps) {
        this.onPropsSetOrChange(nextProps)
    }

    push(path, args) {
        const href = createHRef(this.props.location.pathname, path, args)
        this.props.history.push(href)
        this.state.context.updateContext({ updateLeftLayoutMenu: true })
    }

    pushHref(href) {
        window.open(href)
    }

    render() {
        const { activeObject } = this.state
        const getActiveObject = _.get(activeObject, '_type_', 'ui3.Application');

        return (
            <AppContext.Provider value={this.state.context}>
                <LocaleProvider locale={this.state.locale}>
                    {this.state.user === undefined ?
                        this.state.waitMinute ?
                            <div className="loader">
                                <div className="inner one"></div>
                                <div className="inner two"></div>
                                <div className="inner three"></div>
                            </div>
                            :
                            (<Login
                                user={this.state.user}
                                userName={this.state.userName}
                                password={this.state.password}
                                showLogin={this.state.showLogin}
                                setLang={(lang) => {
                                    this.setLang(lang)
                                }}
                                setState={(state) => {
                                    this.setState(Object.assign({}, this.state, state))
                                }}
                                isSSOEnabled={this.state.isSSOEnabled}
                            />)
                        :
                        (
                            getActiveObject === 'ui3.Application' ?
                                <StartPage
                                    context={this.state.context}
                                    resource={resource}
                                    activeObject={this.state.activeObject}
                                    pathHistory={this.state.pathHistory}
                                    push={path => this.push(path)}
                                    onSelectObject={(object) => {
                                        this.selectObject(object)
                                    }}
                                    setLang={(lang) => {
                                        this.setLang(lang)
                                    }}
                                    pushHref={href => this.pushHref(href)}
                                />
                                :
                                <LayoutPage
                                    setLang={(lang) => {
                                        this.setLang(lang)
                                    }}
                                    pushHref={href => this.pushHref(href)}
                                    onSelectObject={(object, options) => {
                                        this.selectObject(object, options)
                                    }}
                                    pathHistory={this.state.pathHistory}
                                    push={path => this.push(path)}
                                    path={this.state.path}
                                    activeObject={this.state.activeObject}
                                    context={this.state.context}
                                />
                        )
                    }
                </LocaleProvider>
            </AppContext.Provider>
        );
    }

    updateDimensions() {
        this.setState({ innerWidth: window.innerWidth, innerHeight: window.innerHeight });
    }

    componentWillMount() {
        this.updateDimensions()
        this.getLocale()
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    setCurrentBranch = (currentBranch, cb) => {
        if (!currentBranch) {
            currentBranch = localStorage.getItem("currentBranch") || "master"
        }
        resource.query(`/system/branch/${currentBranch}`, {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(branchInfo=>{
            this.state.context.updateContext({branchInfo}, cb)
            localStorage.setItem("currentBranch", branchInfo.current)
        });
    }

    componentDidMount() {
        resource.isSSOEnabled().then(isSSOEnabled => {
            this.setState({isSSOEnabled})
        });
        window.addEventListener("resize", () => this.updateDimensions());
        this.onPropsSetOrChange(this.props)
        resource.setStompConnectedCB((stompConnected) => {
            if (!stompConnected && resource.stompReconnect && resource.stompReconnectCount === 0) {
                resource.logLog("Server connection lost. Reconnecting...")
            }
            this.setState({ stompConnected })
        })

        resource.loginCB = (show) => {
            this.setState({ showLogin: show })
        }
        resource.loginSuccessCB = (user) => {
            this.setState({ user }, ()=>{this.setCurrentBranch(null)})
            this.state.context.updateContext({ user })
        }
        resource.loginCB = (showLogin) => {
            if (showLogin) {
                this.setState({ user: undefined })
            }
        }
        resource.setAlertCB((info, headline) => {
            let btn = (<Button type="link" size="small" onClick={() => notification.destroy()}>
                Close All
            </Button>);
            let key = info;
            notification["info"]({
                message: headline,
                btn,
                description: info,
                key,
                duration: 0,
                style: { width: 600, marginLeft: 335 - 600 }
            })
        }, (error, headline) => {
            let btn = (<Button type="link" size="small" onClick={() => notification.destroy()}>
                Close All
            </Button>);
            let key = error;
            notification["error"]({
                message: headline,
                btn,
                description: error,
                key,
                duration: 0,
                style: { width: 600, marginLeft: 335 - 600 }
            })
        })
        resource.authenticate().then(()=>{
            this.setState({waitMinute: false})
        })
        resource.setFetchCountCB((fetchCount) => {
            this.state.context.updateContext({ fetchCount })
        })
    }

    login(show) {
        this.setState({ showLogin: show })
    }

}

export { Ecore as default, AppContext };
