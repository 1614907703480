import {IconComponent} from "nx-design/__internal__/cjs-src/icons/_Icon/Icon";
import {IconDelete} from "nx-design/IconDelete";
import * as React from "react";
import {IconCheck} from "nx-design/IconCheck";
import {IconRefresh} from "nx-design/IconRefresh";
import {IconBranch} from "nx-design/IconBranch";
import {IconErase} from "nx-design/IconErase";
import {IconDataset} from "nx-design/IconDataset";
import {IconLink} from "nx-design/IconLink";
import {IconLineage} from "nx-design/IconLineage";
import {IconGit} from "nx-design/IconGit";
import {IconDownload} from "nx-design/IconDownload";
import {IconUpload} from "nx-design/IconUpload";
import {IconPlay} from "nx-design/IconPlay";
import {IconLightbulbt} from "nx-design/IconLightbulbt";
import {IconApps} from "nx-design/IconApps";
import {IconCopy} from "nx-design/IconCopy";

export type IconItem = {
    label: string;
    leftIcon: IconComponent;
};

export const iconRightSideBar: IconItem[] = [
    {
        label: 'clear',
        leftIcon: IconDelete
    },
    {
        label: 'git',
        leftIcon: IconGit
    },
    {
        label: 'checkout',
        leftIcon: IconCheck
    },
    {
        label: 'update',
        leftIcon: IconRefresh
    },
    {
        label: 'refresh',
        leftIcon: IconRefresh
    },
    {
        label: 'commit',
        leftIcon: IconBranch
    },
    {
        label: 'cleanup',
        leftIcon: IconErase
    },
    {
        label: 'data',
        leftIcon: IconDataset
    },
    {
        label: 'scripts',
        leftIcon: IconLink
    },
    {
        label: 'dependenc',
        leftIcon: IconLineage
    },
    {
        label: 'importtransformation',
        leftIcon: IconUpload
    },
    {
        label: 'exporttransformation',
        leftIcon: IconDownload
    },
    {
        label: 'importworkflow',
        leftIcon: IconUpload
    },
    {
        label: 'exportworkflow',
        leftIcon: IconDownload
    },
    {
        label: 'validate',
        leftIcon: IconCheck
    },
    {
        label: 'run',
        leftIcon: IconPlay
    },
    {
        label: 'generate',
        leftIcon: IconLightbulbt
    },
    {
        label: 'set',
        leftIcon: IconLightbulbt
    },
    {
        label: 'build',
        leftIcon: IconApps
    },
    {
        label: 'deploy',
        leftIcon: IconCopy
    },

];

export const getIconRightSideBar = <T extends string>(
    name: string
) => {
    let iconRightSideBar_: IconItem | undefined = iconRightSideBar.find((m) =>  name.toLowerCase().includes(m.label));

    if (iconRightSideBar_ !== undefined) {
        const Icon = iconRightSideBar_.leftIcon;
        return <Icon size={"xs"}/>;
    }
    else {
        return undefined;
    }
};