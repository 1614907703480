import './Login.css'
import * as React from 'react';
import {Button} from "nx-design/Button";
import {Grid, GridItem} from "nx-design/Grid";
import { ReactComponent as AppLogo } from "../../img/logo.svg";
import {TextField} from "nx-design/TextField";
import {TextFieldOnChangeArguments} from "nx-design/__internal__/src/components/TextField/helpers";
import {useTranslation, withTranslation} from "react-i18next"
import i18n from "../../i18n";
import {IconGlobe} from "nx-design/__internal__/cjs-src/icons/IconGlobe/IconGlobe";
import resource from "../../Resource";
import {useEffect} from "react";

export const langProp = ['ru-RU', 'en-EN'] as const;
export type LangProp = typeof langProp[number];
export const langPropDefault: LangProp = langProp[0];

type Props = {
    userName: string;
    password: string;
    setLang: (lang: LangProp) => void;
    setState: (state: any) => void;
    isSSOEnabled: boolean;
};

function Login (props: Props) {

    const {
        setLang,
        setState,
        userName,
        password,
        isSSOEnabled
    } = props;

    const { t } = useTranslation()

    const handleClick = () => {
        setLang( i18n.language === langProp[0] ? langProp[1] : langProp[0]);
    };

    const handleChangeLogin = (arg: TextFieldOnChangeArguments) => {
        setState({ userName: arg.value })
    };

    const handleChangePassword = (arg: TextFieldOnChangeArguments) => {
        setState({ password: arg.value })
    };

    useEffect(() => {
        document.title = 'Login - Datagram';
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    const classWindowSSO =
        isSSOEnabled ?
            "window window-sso-height" :
                "window window-height";

    return (
        <div className="authorization">
            <Grid cols="3" xAlign="center" yAlign="center">
                <GridItem/>
                <GridItem>
                    <div className={classWindowSSO}>
                        <Grid  cols="1" xAlign="center" yAlign="center" rowGap="xl">
                            <Grid cols="2" className="login-header" >
                                <GridItem col="1">
                                    <AppLogo/>
                                </GridItem>
                                <GridItem col="1" className="header-lang">
                                    <Button
                                        id="button-lang"
                                        view="clear"
                                        className="button-lang"
                                        size="m"
                                        iconLeft={<IconGlobe className="icon-lang" size={"xs"}/>}
                                        label={i18n.language.split("-")[1] || langProp[0].split("-")[1]}
                                        onClick={() => handleClick()}
                                    />
                                </GridItem>
                            </Grid>
                            <Grid rowGap="s">
                                <GridItem>
                                    <TextField
                                        autoFocus
                                        id="input-login"
                                        className="input-text"
                                        label={t('username')}
                                        value={userName}
                                        onChange={(args:TextFieldOnChangeArguments) => handleChangeLogin(args)}
                                        withClearButton={true}
                                        onKeyUp={e => {
                                            if (e.keyCode === 13) {
                                                resource.authenticate(userName, password)
                                            }
                                        }}
                                    />
                                </GridItem>
                                <GridItem>
                                    <TextField
                                        id="input-password"
                                        className="input-text"
                                        label={t('password')}
                                        value={password}
                                        type={"password"}
                                        onChange={(args:TextFieldOnChangeArguments) => handleChangePassword(args)}
                                        withClearButton={true}
                                        onKeyUp={e => {
                                            if (e.keyCode === 13) {
                                                resource.authenticate(userName, password)
                                            }
                                        }}
                                    />
                                </GridItem>
                            </Grid>
                            <GridItem>
                                <Button
                                    size={"l"}
                                    className="button"
                                    label={t('login')}
                                    onClick={(e) => {
                                        resource.authenticate(userName, password)
                                    }}
                                />
                            </GridItem>
                            {isSSOEnabled &&
                                <GridItem>
                                    <Button
                                        size={"l"}
                                        className="button"
                                        label={t('loginSso')}
                                        onClick={(e) => {
                                            window.location.href="/sso"
                                        }}
                                    />
                                </GridItem>
                            }
                        </Grid>
                    </div>
                </GridItem>
                <GridItem/>
            </Grid>
        </div>

    );

}

export default withTranslation()(Login);
