import './RightSideBar.css'
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next'
import {Typography} from "nx-design/Typography";
import {Button} from "nx-design/Button";
import {IconCopy} from "nx-design/IconCopy";
import {IconDelete} from "nx-design/IconDelete";
import {IconEllipsisV} from "nx-design/IconEllipsisV";
import {ContextMenu, ContextMenuItemDefault} from "nx-design/ContextMenu";
import {getClassDef} from "../../../model";
import {ButtonTooltip, pathItem, splitName} from "../../startPage/helpers";
import update from "immutability-helper";
import _ from "lodash";
import {modifyBeforeSave} from "../../../utils/meta";
import resource from "../../../Resource";
import FieldList from "./FieldList";
import {IconComponent} from "nx-design/__internal__/cjs-src/icons/_Icon/Icon";
import {IconDownload} from "nx-design/IconDownload";
import {IconUpload} from "nx-design/IconUpload";
import {IconCode} from "nx-design/IconCode";
import {getIconRightSideBar} from "./helpers";
import {IconTree} from "nx-design/IconTree";
import {IconPlay} from "nx-design/IconPlay";
import {Modal} from "nx-design/Modal";
import {IconBlock} from "nx-design/IconBlock";
import {IconCheck} from "nx-design/IconCheck";
import SideBar, {TypeSidebarProp, typeSidebarProp} from "../../sideBar/SideBar";
import {Tooltip} from "antd";
import {IconHelpCircle} from "nx-design/IconHelpCircle";

export type PropRightSideBar = {
    activeObject: pathItem;
    path: any[];
    push: (path: string) => void;
    typeSidebar: TypeSidebarProp,
    stateSidebar: boolean;
    onCloseSideBar: (clickSave: boolean) => void;
    onSelectObject: (arg: object) => void;
    context: any;
    refreshTable: () => void;
};

function RightSideBar (props: PropRightSideBar) {

    const {
        activeObject,
        stateSidebar,
        typeSidebar,
        onCloseSideBar,
        onSelectObject,
        context,
        refreshTable,
        path,
        push
    } = props;

    const { t } = useTranslation();

    const [isProgressLineVisible, setProgressLineVisible] = useState(false);
    const [isOpenActions, setIsOpenActions] = useState(false);
    const [itemActions, setItemActions] = useState<ContextMenuItemDefault[]>([]);
    //Редактируемый объект до сохранения изменений
    const [entity, setEntity] = useState<any>(activeObject);
    const [eDocumentation, setEDocumentation] = useState<any>();
    const refActions = useRef(null);
    const classDef = getClassDef(activeObject._type_);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [modalAction, setModalAction] = useState<any>();

    useEffect(() => {
        getEntity(activeObject._type_, activeObject.e_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeObject, typeSidebar]);

    const getSubItemActions = (label: string, subItemActions: any[], icon: IconComponent, groupId: number, eDocumentation: any ) => {
        if (subItemActions.length > 0) {
            const Icon = icon;
            const item: ContextMenuItemDefault = {
                    label: label,
                    key: label,
                    leftSide: <Icon size={"xs"}/>,
                    groupId: groupId,
                    subMenu: subItemActions.map((action: any) => {
                        let doc = null;
                        if (eDocumentation !== undefined && eDocumentation[action.name] !== undefined) {doc = eDocumentation[action.name];}
                        const rightIcon = doc ? <Tooltip title={doc.split('. ').map((d: any) => {return <p>{d}</p>})}><IconHelpCircle size={"xs"} view={"secondary"}/></Tooltip> : <></>;
                        return {
                            label: t(activeObject._type_ + '.ops.' + action.name + '.caption', { ns: 'classes' }),
                            key: action.name,
                            onClick: (params: any) => {onActionClick(params)},
                            rightSide: rightIcon,
                            withSubMenu: true,
                            leftSide: getIconRightSideBar(action.name) || <div style={{marginLeft: '15px'}}/>,
                        }
                    })
                }
            return item;
        }
        else {
            const item: ContextMenuItemDefault = {
                    label: t(activeObject._type_ + '.ops.' + subItemActions[0].name + '.caption', { ns: 'classes' }),
                    key: subItemActions[0].name,
                    leftSide: getIconRightSideBar(subItemActions[0].name) || <div style={{marginLeft: '15px'}}/>,
                    groupId: groupId,
                    onClick: (params: any) => {onActionClick(params)},
                }
            return item;
        }
    }

    const getItemActions = (eDocumentation: any) => {
        const itemActions: ContextMenuItemDefault[] = [];
        if ( classDef && classDef.actions && classDef.actions.length > 0 ) {
            const importMenu = classDef.actions.filter((f: any) => f.name.toLowerCase().includes('import') || f.name.toLowerCase().includes('upload')  );
            const exportMenu = classDef.actions.filter((f: any) => f.name.toLowerCase().includes('export') || f.name.toLowerCase().includes('download') );
            const svnMenu = classDef.actions.filter((f: any) => f.name.toLowerCase().includes('svn') );
            const testMenu = classDef.actions.filter((f: any) => f.name.toLowerCase().includes('test') );
            const runMenu = classDef.actions.filter((f: any) => f.name.toLowerCase().includes('run') );

            const otherMenu = classDef.actions.filter((f: any) =>
                !f.name.toLowerCase().includes('import') &&
                !f.name.toLowerCase().includes('upload') &&
                !f.name.toLowerCase().includes('export') &&
                !f.name.toLowerCase().includes('download') &&
                !f.name.toLowerCase().includes('svn') &&
                !f.name.toLowerCase().includes('test') &&
                !f.name.toLowerCase().includes('run')
            );

            if (importMenu.length !== 0) {itemActions.push(getSubItemActions(t('upload'), importMenu, IconUpload, 1, eDocumentation));}
            if (exportMenu.length !== 0) {itemActions.push(getSubItemActions(t('download'), exportMenu, IconDownload, 1, eDocumentation));}
            if (svnMenu.length !== 0) {itemActions.push(getSubItemActions('SVN', svnMenu, IconCode, 2, eDocumentation));}
            if (testMenu.length !== 0) {itemActions.push(getSubItemActions(t('tests'), testMenu, IconTree, 3, eDocumentation));}
            if (runMenu.length !== 0) {itemActions.push(getSubItemActions(t('run'), runMenu, IconPlay, 4, eDocumentation));}

            otherMenu.forEach((action: any) => {
                let doc = null;
                if (eDocumentation !== undefined && eDocumentation[action.name] !== undefined) {doc = eDocumentation[action.name];}
                const rightIcon = doc ? <><Tooltip title={doc.split('. ').map((d: any) => {return <p>{d}</p>})}><IconHelpCircle size={"xs"} view={"secondary"}/></Tooltip><div style={{marginLeft: '15px'}}/></> : <div style={{marginLeft: '15px'}}/>;

                const item: ContextMenuItemDefault =
                    {
                        label: t(activeObject._type_ + '.ops.' + action.name + '.caption', { ns: 'classes' }),
                        key: action.name,
                        leftSide: getIconRightSideBar(action.name) || <div style={{marginLeft: '15px'}}/>,
                        rightSide: rightIcon,
                        onClick: (params: any) => {onActionClick(params)},
                        groupId: 5,
                    }
                itemActions.push(item);
            })

            setItemActions(itemActions);
        }
    }

    const onActionClick = (params: any) => {
        const actionIndex = classDef.actions.findIndex((a: any) => a.name === params.item.key)
        if (actionIndex >= 0) {
            const action = classDef.actions[actionIndex]
            if (!action.parameters || action.parameters.length === 0) {
                setProgressLineVisible(true);
                if (action.file) {
                    resource.download(entity, action.name, {export:true}, `${entity.name}.zip`);
                    setProgressLineVisible(false);
                }
                else {
                    resource.call(entity, action.name).then(json => {
                        window.dispatchEvent(new Event(action.name));
                        resource.logInfo(JSON.stringify(json, null, 2));
                        refreshTable();
                        setProgressLineVisible(false);
                        context.updateContext({ updateLeftLayoutMenu: true })
                    }).catch(() => setProgressLineVisible(false))
                }
            }
            else {
                setModalAction(action)
                setIsModalOpen(true)
            }
        }
        return
    };

    const getEntity = (_type_: string, e_id: any) => {
        if (typeSidebar === typeSidebarProp[0]) {
            if (context.entity && activeObject.e_id === context.entity.e_id) {
                setEntity(context.entity)
                setEDocumentation(entity.eDocumentation)
                getItemActions(entity.eDocumentation);
            }
            else if (_type_ && e_id) {
                return resource.getEntity(_type_, e_id).then(entity => {
                    setEntity(entity)
                    setEDocumentation(entity.eDocumentation)
                    getItemActions(entity.eDocumentation);
                    context.updateContext({ entity })
                })
            }
        }
        else if (typeSidebar === typeSidebarProp[1] && activeObject.name !== "" && path.length > 2) {
            const entityNew = {_type_: path[2].name, name: "", e_id: undefined}
            setEntity(entityNew)
            if (path.length > 3) {path.pop()}
            path.push(entityNew);
            push(JSON.parse(JSON.stringify(path)))
            resource.getEDocumentation(entityNew._type_).then(json => {
                setEDocumentation(json.eDocumentation)
            })
        }
        else if (typeSidebar === typeSidebarProp[1] && activeObject.name === "") {
            const entityNew = {_type_: path[2].name, name: "", e_id: undefined}
            setEntity(entityNew)
            resource.getEDocumentation(entityNew._type_).then(json => {
                setEDocumentation(json.eDocumentation)
            })
        }

        return Promise.resolve(null);
    }

    const deleteProp = (value: any, propName: any, parent: any, isUseToRestore?: any, removeCondition?: any, ready?: any) => {
        if(!ready) {
            ready = []
        }
        if (typeof value !== "object") {
            if (!Array.isArray(value)) {
                return value
            }
        }
        if (!Array.isArray(value)) {
            if (value) {
                if (value.hasOwnProperty(propName)) {
                    delete value[propName]
                }
                if(ready.indexOf(value) === -1) {
                    ready.push(value)
                    Object.entries(value).forEach((e) => {
                        deleteProp(e[1], propName, parent + "." + e[0], isUseToRestore, removeCondition, ready)
                    })
                }
            }
        } else {
            if(ready.indexOf(value) === -1) {
                ready.push(value)
                value.forEach((node, index) => {
                    deleteProp(node, propName, parent + '[' + index + ']', isUseToRestore, removeCondition, ready)
                })
            }
        }
        return value
    }

    const removeTransient = (entity: any) => {
        return _.cloneDeepWith(entity, (value) => deleteProp(value, "transient", "entity"))
    }

    const save = () => {
        setProgressLineVisible(true);
        let e = _.cloneDeep(entity);
        e = removeTransient(e)

        const afterSave = modifyBeforeSave(e)
        let prom = resource.saveEntity(e)
        prom = afterSave(prom)

        prom.then(entity => {
            refreshTable()
            setEntity(entity)
            context.updateContext({ entity })
            setProgressLineVisible(false);

            if (path.length > 3) {path.pop()};
            path.push({_type_: entity._type_, name: entity.name, e_id: entity.e_id});
            push(JSON.parse(JSON.stringify(path)));
        }).catch( error => {
            setProgressLineVisible(false)
        } )
    }

    const onDelete = () => {
        resource.deleteEntity(entity).then(entity => {
            onCloseSideBar(false);
            path.pop();
            push(JSON.parse(JSON.stringify(path)));
            setEntity(undefined)
        })
    };

    const onSave = () => {
        save();
    };

    const onCancel = () => {
        setProgressLineVisible(true);
        if (activeObject._type_) {
            // @ts-ignore
            getEntity(activeObject._type_, activeObject.e_id).then(() => {
                setTimeout(() => {setProgressLineVisible(false)}, 300);
            })
        }
    };

    const onClose = () => {
        context.updateContext({ entity });
        onCloseSideBar(false);
    };

    const onCopy = () => {
        if (entity.e_id) {
            const newName = prompt(t('copyof'), `Copy_of_${entity.name}`);
            if (newName) {
                resource.copyEntity(entity._type_, entity.e_id, newName).then(newEntity => {
                    path.pop();
                    path.push({_type_: newEntity._type_, name: newEntity.name, e_id: newEntity.e_id});
                    push(JSON.parse(JSON.stringify(path)));
                })
            }
        }
    };

    return (
        <SideBar
            stateSidebar = {stateSidebar}
            label = {t(typeSidebar) + ": " + t(splitName(activeObject._type_)) + " " + activeObject.name}
            actionButton = {<>
                <ButtonTooltip
                    onlyIcon
                    iconLeft={IconCopy}
                    tooltipProps={{ content: t('copy'), direction: 'downCenter' }}
                    view={"clear"}
                    size={"xs"}
                    onClick={() => onCopy()}
                />
                <ButtonTooltip
                    onlyIcon
                    iconLeft={IconDelete}
                    tooltipProps={{ content: t('delete'), direction: 'downCenter' }}
                    view={"clear"}
                    onClick={() => onDelete()}
                    size={"xs"}
                />
                <div className={'sidebar-line'}/>
                {
                    itemActions.length !== 0 &&
                    <div className={'sidebar-action-button'}>
                        <ButtonTooltip
                            onlyIcon
                            iconLeft={IconEllipsisV}
                            tooltipProps={{ content: t('evs.Rule.attrs.actions.caption', {ns: ['classes']}), direction: 'downCenter' }}
                            view={"clear"}
                            size={"xs"}
                            onClick={() => setIsOpenActions(!isOpenActions)}
                            ref={refActions}
                        />
                        <ContextMenu
                            isOpen={isOpenActions}
                            items={itemActions}
                            anchorRef={refActions}
                            direction="downCenter"
                            subMenuDirection="rightStartUp"
                            onClickOutside={() => setIsOpenActions(false)}
                            size={"s"}
                            offset={"m"}
                            theme={"secondary"}
                        />
                    </div>
                }
            </>}
            content = {<>
                <Modal
                    isOpen={isModalOpen}
                    hasOverlay
                    onOverlayClick={(): void => setIsModalOpen(false)}
                    className={'sidebar-modal'}
                >
                    <Typography size={"l"} className={'sidebar-modal-title'} >{t('parameters')}</Typography>
                    <div>
                        {(modalAction === undefined || !modalAction.file) &&
                            <FieldList
                                fields={modalAction !== undefined ? classDef.actions.find((a: any) => a.name === modalAction.name) !== undefined ? classDef.actions.find((a: any) => a.name === modalAction.name).parameters : [] : []}
                                entity={entity}
                                eDocumentation={eDocumentation}
                                actionName={modalAction !== undefined ? modalAction.name : ""}
                                showHidden={true}
                                readOnly={false}
                                selectObject={onSelectObject}
                                updateContext={context.updateContext}
                                updateEntity={(value: any) => setEntity( update(entity, { $merge: value })  )}
                                t={t}
                            />
                        }
                    </div>
                    <div className={'sidebar-modal-actions'}>
                        <Button
                            className={'sidebar-modal-actions-button'}
                            size="m"
                            view="primary"
                            label={t('apply')}
                            iconLeft={IconCheck}
                            iconSize={"xs"}
                            width="default"
                            onClick={(): void => {
                                setIsModalOpen(false)
                                setModalAction(undefined)

                                if (modalAction.file) {
                                    resource.download(activeObject, modalAction.name, {export:true}, `${activeObject.name}.zip`);
                                    setProgressLineVisible(false);
                                }
                                else {
                                    setProgressLineVisible(true);
                                    resource.callByName(entity, modalAction.name, entity).then(json => {
                                        window.dispatchEvent(new Event(modalAction.name));
                                        resource.logInfo(JSON.stringify(json, null, 2));
                                        refreshTable();
                                        setProgressLineVisible(false);
                                    })
                                }
                            }}
                        />
                        <Button
                            className={'sidebar-modal-actions-button'}
                            size="m"
                            view="secondary"
                            label={t('cancel')}
                            width="default"
                            iconLeft={IconBlock}
                            iconSize={"xs"}
                            onClick={(): void => {
                                setIsModalOpen(false)
                                setModalAction(undefined)
                            }}
                        />
                    </div>
                </Modal>
                {classDef.fields !== undefined && entity !== undefined && path[3] &&
                    <FieldList
                        fields={classDef.fields}
                        eDocumentation={eDocumentation}
                        entity={entity}
                        showHidden={true}
                        readOnly={false}
                        selectObject={onSelectObject}
                        updateContext={context.updateContext}

                        updateEntity={(value: any) => setEntity( update(entity, { $merge: value })  )}
                        t={t}
                    />
                }
            </>}
            onClose = {() => onClose()}
            onSave = {() => onSave()}
            onCancel = {() => onCancel()}
            isProgressLineVisible={isProgressLineVisible}
        />
    );

}

export default withTranslation()(RightSideBar);