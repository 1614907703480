import './LeftMenu.css'
import * as React from 'react';
import {useTranslation, withTranslation} from "react-i18next"
import {items, renderIcon, splitName, ViewProp, viewPropDefault} from "../helpers";
import ButtonView from "../buttonView/ButtonView";
import {Typography} from "nx-design/Typography";

type PropsLeftMenu = {
    view: ViewProp;
    animationOn: boolean;
    push: (path: string) => void;
};

function LeftMenu (props: PropsLeftMenu) {

    const {
        view,
        animationOn,
        push
    } = props;

    const { t } = useTranslation()

    const classNameAnimationMenuView =
        animationOn && view === viewPropDefault ?
            "MenuViewCompact" :
            animationOn && view !== viewPropDefault ?
                "MenuViewExtended" : "";

    const classNameAnimationLeftMenu =
        animationOn && view === viewPropDefault ?
            "LeftMenuCompact" :
            animationOn && view !== viewPropDefault ?
                "LeftMenuExtended" : "";

    return (
        <div className={`${classNameAnimationLeftMenu} left-menu`}>
            {view === viewPropDefault && items[0].subMenu !== undefined && items[0].subMenu.map((m) => {
                return <ButtonView
                    key={m.key}
                    typeName={m.type}
                    viewPage={view}
                    keyName={m.label}
                    menu={m}
                    label={t(m.label)}
                    push={push}
                />
            })}
            {view !== viewPropDefault
                && items[0].subMenu !== undefined && items[0].subMenu.map((m, index) => {
                    return <div
                        key={m.key + index}

                        className={classNameAnimationMenuView}
                        >
                        <div className={"menu-view"}>
                            {renderIcon(m, "m")}
                            <Typography className={"typo-view"} size={"xl"}> {t(m.label)} </Typography>
                        </div>
                        <div>
                            {
                                m.subMenu !== undefined && m.subMenu.map((mm, index) => {
                                    return <ButtonView
                                        key={mm.key + index}
                                        push={push}
                                        typeName={mm.type}
                                        viewPage={view}
                                        keyName={mm.key}
                                        menu={mm}
                                        label={t(splitName(mm.label))}
                                    />
                                })
                            }
                        </div>
                    </div>
                })
            }
        </div>
    );

}

export default withTranslation()(LeftMenu);
