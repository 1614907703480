import './Logs.css'
import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {useTranslation, withTranslation} from 'react-i18next'
import {Table} from "nx-design/Table";
import resource from "../../../Resource";
import reactStringReplace from "react-string-replace";
import {TextField} from "nx-design/TextField";
import {Typography} from "nx-design/Typography";
import {IconCheckC} from "nx-design/IconCheckC";
import {IconWarning} from "nx-design/IconWarning";
import {IconWarningC} from "nx-design/IconWarningC";
import {ButtonTooltip} from "../../startPage/helpers";
// import {testRows} from "../helpers";

export type PropLogs = {
    clearLog: boolean;
    searchLog: boolean;
    isErrorFilter: boolean;
    scrollDown: boolean;
    scrollBegin: boolean;
};

function Logs (props: PropLogs) {

    const {
        clearLog,
        searchLog,
        isErrorFilter,
        scrollDown,
        scrollBegin
    } = props;

    const el = useRef<null | HTMLDivElement>(null);

    const [logs, setLogs] = useState<any[]>([]);
    const [filterStr, setFilterStr] = useState<string | undefined>(undefined);

    const { t } = useTranslation()

    const upgradeLogs = () => {
        const rows = resource.logControl.logs;
        // const rows = testRows;
        const logs_ = rows.map((log: any, index: number) => {
            return {
                id: index,
                "stacktrace": log.stacktrace,
                "type": log.level,
                "message": log.message,
                "timestamp": log.timestamp
            }
        })
        let logsFilter = [];
        if (isErrorFilter) (logsFilter = logs_.filter(value => value.type === "ERROR"))
        else {logsFilter = logs_}
        setLogs(logsFilter)
    }

    const columns = [
        {
            title: 'id',
            accessor: 'id',
            hidden: true
        },
        {
            title: 'Type',
            accessor: 'type',
            width: 160,
            renderCell: (row: any) => {
                return (
                    <div style={{ width: '100%' }}>
                        {
                            row.type === "INFO" ?
                                <Typography size={"m"} className={'logs-type-info'}><IconCheckC className={'logs-type-icon'} size={"xs"}/>Info</Typography> :
                            row.type === "ERROR" ?
                                <Typography size={"m"} className={'logs-type-error'}><IconWarning className={'logs-type-icon'} size={"xs"}/>Error</Typography> :
                                <Typography size={"m"} className={'logs-type-warning'}><IconWarningC className={'logs-type-icon'} size={"xs"}/>Warning</Typography>
                        }
                        {
                            row.stacktrace !== undefined ?
                                <ButtonTooltip
                                    onlyIcon
                                    iconLeft={IconWarningC}
                                    className={'logs-type-icon-error'}
                                    size={'xs'}
                                    iconSize={'xs'}
                                    view={'clear'}
                                    tooltipProps={{
                                        content: row.stacktrace,
                                        direction: 'upRight',
                                        spareDirection: 'upRight',
                                        size: 'window',
                                        mode: 'click',
                                        closeOnClickOutside: true
                                }}
                                />
                                : <></>
                        }
                    </div>
                );
            }
        },
        {
            title: 'Timestamp',
            accessor: 'timestamp',

        },
        {
            title: 'Message',
            accessor: 'message',
        },
        {
            title: 'stacktrace',
            accessor: 'stacktrace',
            hidden: true,
        },
    ]

    useEffect(() => {
        upgradeLogs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[resource.logControl.logs, isErrorFilter]);

    useEffect(() => {
        if (clearLog) {
            setLogs([])
            resource.logControl.logs = []
        }
    },[clearLog]);

    useEffect(() => {
        if (el.current !== null) {
            el.current.scrollTop = el.current.scrollHeight;
        }
    });

    useEffect(() => {
        if (el.current !== null) {
            el.current.scrollTop = 0;
        }
    },[scrollBegin]);

    useEffect(() => {
        if (el.current !== null) {
            el.current.scrollTop = el.current.scrollHeight;
        }
    },[scrollDown]);

    const match = (str: string) => {
        if (!filterStr) {
            return true
        }
        return str !== undefined && str !== null && str.toUpperCase().indexOf(filterStr.toUpperCase()) >= 0
    }

    const getDataSource = () => {
        if (!filterStr || ( filterStr !== undefined && filterStr.length === 0 ) ) {
            return logs
        }
        return logs.filter(value =>
            match(value.message) || match(value.stacktrace)
        ).map(value => {
            return {
                ...value,
                message: reactStringReplace(value.message, filterStr, (match, i) => (
                    <span key={i} style={{color: 'red'}}>{match}</span>
                )),
                stacktrace: value.stacktrace && reactStringReplace(value.stacktrace, filterStr, (match, i) => (
                    <span key={i} style={{color: 'red'}}>{match}</span>
                ))
            }
        })
    }

    return (
        <div
            className={'logs'}
            style={{['--logs-height' as string]: `${searchLog && resource.logControl.logs.length !== 0 ? 'calc(100% - 47px)' : 'unset'}`}}
        >
            {searchLog &&
                <div className={'logs-text-field'}>
                    <TextField
                        style={{display: "block"}}
                        value={filterStr !== undefined ? filterStr.toLowerCase() : ""}

                        placeholder={t('search')}
                        onChange={e => {
                            const filterStr = e.value !== null ? e.value.toUpperCase() : '';
                            setFilterStr(filterStr)
                        }}
                    />
                </div>
            }
                <Table
                    ref={el}
                    columns={columns}
                    rows={getDataSource()}
                    separateRows={true}
                    size={"m"}
                    stickyHeader
                    emptyRowsPlaceholder={t('nodata')}
                />
        </div>

    );

}

export default withTranslation()(Logs);
