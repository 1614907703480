import React from 'react';
import Ecore from "./Ecore";
import 'antd/dist/antd.min.css';
import './css/App.css';
import './css/nx-design.css';
import {presetDatagram, Theme} from "nx-design/Theme";

const App: React.FC = (props) => {
    return (
        <div>
            <Theme preset={presetDatagram}>
                <Ecore {...props}/>
            </Theme>
        </div>
    );
};

export default App;
