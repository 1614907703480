import {IconComponent, IconPropSize} from "nx-design/__internal__/cjs-src/icons/_Icon/Icon";
import {IconOrganization} from "nx-design/__internal__/cjs-src/icons/IconOrganization/IconOrganization";
import {IconDisconnect} from "nx-design/IconDisconnect";
import {IconBranch} from "nx-design/IconBranch";
import {IconApps} from "nx-design/IconApps";
import * as React from "react";
import {IconCojob} from "nx-design/__internal__/cjs-src/icons/IconCojob/IconCojob";
import {IconJdbcConnect} from "nx-design/__internal__/cjs-src/icons/IconJdbcConnect/IconJdbcConnect";
import {IconScheme} from "nx-design/__internal__/cjs-src/icons/IconScheme/IconScheme";
import {IconFunction} from "nx-design/__internal__/cjs-src/icons/IconFunction/IconFunction";
import {IconWorkflow} from "nx-design/__internal__/cjs-src/icons/IconWorkflow/IconWorkflow";
import {IconSettings} from "nx-design/__internal__/cjs-src/icons/IconSettings/IconSettings";
import {IconCube} from "nx-design/__internal__/cjs-src/icons/IconCube/IconCube";
import {IconOozie} from "nx-design/__internal__/cjs-src/icons/IconOozie/IconOozie";
import {IconServer} from "nx-design/IconServer";
import {IconAirflow} from "nx-design/__internal__/cjs-src/icons/IconAirflow/IconAirflow";
import {IconZeppelin} from "nx-design/__internal__/cjs-src/icons/IconZeppelin/IconZeppelin";
import {IconAtlas} from "nx-design/__internal__/cjs-src/icons/IconAtlas/IconAtlas";
import {IconBaseServer} from "nx-design/IconBaseServer";
import {IconStream} from "nx-design/__internal__/cjs-src/icons/IconStream/IconStream";
import {IconPen} from "nx-design/__internal__/cjs-src/icons/IconPen/IconPen";
import {IconDataset} from "nx-design/__internal__/cjs-src/icons/IconDataset/IconDataset";
import {IconSoftwareSystem} from "nx-design/__internal__/cjs-src/icons/IconSoftwareSystem/IconSoftwareSystem";
import {IconJdbcBase} from "nx-design/__internal__/cjs-src/icons/IconJdbcBase/IconJdbcBase";
import {IconDeployment} from "nx-design/__internal__/cjs-src/icons/IconDeployment/IconDeployment";
import {IconDocumentSettings} from "nx-design/__internal__/cjs-src/icons/IconDocumentSettings/IconDocumentSettings";
import {IconSettingsPlay} from "nx-design/__internal__/cjs-src/icons/IconSettingsPlay/IconSettingsPlay";
import {IconEnvironment} from "nx-design/__internal__/cjs-src/icons/IconEnvironment/IconEnvironment";
import {IconRocket} from "nx-design/__internal__/cjs-src/icons/IconRocket/IconRocket";
import {IconTasks} from "nx-design/IconTasks";
import {IconHome} from "nx-design/IconHome";
import {withTooltip} from "nx-design/withTooltip";
import {Button} from "nx-design/Button";

export type PropStartPage = {
    onSelectObject: (arg: object) => void;
    setLang: (lang: LangProp) => void;
    pathHistory: pathItem[][];
    push: (path: string) => void;
    pushHref: (href: string) => void;
    context: any;
};

export const langProp = ['ru-RU', 'en-EN'] as const;
export type LangProp = typeof langProp[number];
export const langPropDefault: LangProp = langProp[0];

export const viewProp = ['extended', 'compact'] as const;
export type ViewProp = typeof viewProp[number];
export const viewPropDefault: ViewProp = viewProp[0];

const gridProp = {
    extended: ["12", "1", "11", "1", "7", "3", "1"],
    compact: ["12", "2", "10", "2", "5", "3", "2"]
}

export const renderGrid = <T extends String>(
    view: ViewProp,
    index: number
) => {
    return view === viewPropDefault ? gridProp.compact[index - 1] : gridProp.extended[index - 1];
};

export type pathItem = {
    name: string;
    _type_: string;
    e_id?: string;
    icon?: IconComponent;
};

export type linkItem = {
    name: string;
    query?: string;
    backReference?: string;
    _type_: string;
    attribute?: string;
};

export type Dependency = {
    label: string;
    type: string;
    e_id: string;
    leftIcon: IconComponent;
    subMenu?: Item[];
    backReference: any;
};

export type Item = {
    label: string;
    leftIcon: IconComponent;
    type?: string | undefined;
    subMenu?: Item[];
    onClick?: (e: any) => void;
    key: string;
    e_id?: string;
};

export const items: Item[] = [
    {
        label: 'Datagram',
        leftIcon: IconHome,
        type: 'ui3.Application',
        key: 'Datagram',
        subMenu: [
            {
                label: 'ETL',
                leftIcon: IconOrganization,
                type: 'ui3.Module',
                key: 'ETL',
                subMenu: [
                    {
                        label: 'etl.Project',
                        leftIcon: IconCube,
                        type: 'ecore.EClass',
                        key: 'etl.Project',
                    },
                    {
                        label: 'etl.Workflow',
                        leftIcon: IconStream,
                        type: 'ecore.EClass',
                        key: 'etl.Workflow'
                    },
                    {
                        label: 'etl.Transformation',
                        leftIcon: IconSettings,
                        type: 'ecore.EClass',
                        key: 'etl.Transformation'
                    },
                    {
                        label: 'etl.UserDefinedFunction',
                        leftIcon: IconFunction,
                        type: 'ecore.EClass',
                        key: 'etl.UserDefinedFunction'
                    },
                    {
                        label: 'etl.CoJob',
                        leftIcon: IconCojob,
                        type: 'ecore.EClass',
                        key: 'etl.CoJob'
                    },
                    {
                        label: 'etl.SchemeDataSet',
                        leftIcon: IconDataset,
                        type: 'ecore.EClass',
                        key: 'etl.SchemeDataSet'
                    },
                    {
                        label: 'etl.MavenDependency',
                        leftIcon: IconPen,
                        type: 'ecore.EClass',
                        key: 'etl.MavenDependency'
                    },
                ]
            },
            {
                label: 'Connections',
                leftIcon: IconDisconnect,
                type: 'ui3.Module',
                key: 'Connections',
                subMenu: [
                    {
                        label: 'rel.Scheme',
                        leftIcon: IconScheme,
                        type: 'ecore.EClass',
                        key: 'rel.Scheme'
                    },
                    {
                        label: 'rt.SoftwareSystem',
                        leftIcon: IconSoftwareSystem,
                        type: 'ecore.EClass',
                        key: 'rt.SoftwareSystem'
                    },
                    {
                        label: 'etl.JdbcContext',
                        leftIcon: IconJdbcBase,
                        type: 'ecore.EClass',
                        key: 'etl.JdbcContext'
                    },
                    {
                        label: 'rt.JdbcConnection',
                        leftIcon: IconJdbcConnect,
                        type: 'ecore.EClass',
                        key: 'rt.JdbcConnection'
                    },
                    {
                        label: 'rt.Deployment',
                        leftIcon: IconDeployment,
                        type: 'ecore.EClass',
                        key: 'rt.Deployment'
                    },
                    {
                        label: 'rt.FileSystem',
                        leftIcon: IconDocumentSettings,
                        type: 'ecore.EClass',
                        key: 'rt.FileSystem'
                    },
                ]
            },
            {
                label: 'Deployment',
                leftIcon: IconBranch,
                type: 'ui3.Module',
                key: 'Deployment',
                subMenu: [
                    {
                        label: 'rt.TransformationDeployment',
                        leftIcon: IconSettingsPlay,
                        type: 'ecore.EClass',
                        key: 'rt.TransformationDeployment'
                    },
                    {
                        label: 'rt.WorkflowDeployment',
                        leftIcon: IconWorkflow,
                        type: 'ecore.EClass',
                        key: 'rt.WorkflowDeployment'
                    },
                    {
                        label: 'rt.CoordinatorDeployment',
                        leftIcon: IconRocket,
                        type: 'ecore.EClass',
                        key: 'rt.CoordinatorDeployment'
                    },
                    // {
                    //     label: 'rt.ImportWizard',
                    //     leftIcon: IconImport,
                    //     type: 'ecore.EClass',
                    //     key: 'rt.ImportWizard'
                    // },
                    {
                        label: 'rt.Environment',
                        leftIcon: IconEnvironment,
                        type: 'ecore.EClass',
                        key: 'rt.Environment'
                    },
                    // {
                    //     label: 'rt.RunTask',
                    //     leftIcon: IconPlayC,
                    //     type: 'ecore.EClass',
                    //     key: 'rt.RunTask',
                    //     subMenu: [
                    //         {
                    //             label: 'rt.IterationTask',
                    //             leftIcon: IconPlayC,
                    //             type: 'ecore.EClass',
                    //             key: 'rt.RunTask'
                    //         },
                    //         {
                    //             label: 'rt.ParametrizedTask',
                    //             leftIcon: IconPlayC,
                    //             type: 'ecore.EClass',
                    //             key: 'rt.RunTask'
                    //         },
                    //     ],
                    // },
                ]
            },
            {
                label: 'Servers',
                leftIcon: IconApps,
                type: 'ui3.Module',
                key: 'Servers',
                subMenu: [
                    {
                        label: 'rt.LivyServer',
                        leftIcon: IconServer,
                        type: 'ecore.EClass',
                        key: 'rt.LivyServer',
                    },
                    {
                        label: 'rt.Oozie',
                        leftIcon: IconOozie,
                        type: 'ecore.EClass',
                        key: 'rt.Oozie',
                    },
                    {
                        label: 'rt.Airflow',
                        leftIcon: IconAirflow,
                        type: 'ecore.EClass',
                        key: 'rt.Airflow',
                    },
                    {
                        label: 'rt.Zeppelin',
                        leftIcon: IconZeppelin,
                        type: 'ecore.EClass',
                        key: 'rt.Zeppelin',
                    },
                    {
                        label: 'rt.ScheduledTask',
                        leftIcon: IconTasks,
                        type: 'ecore.EClass',
                        key: 'rt.ScheduledTask',
                    },
                    {
                        label: 'rt.Atlas',
                        leftIcon: IconAtlas,
                        type: 'ecore.EClass',
                        key: 'rt.Atlas',
                    },
                    {
                        label: 'rt.MLFlowServer',
                        leftIcon: IconBaseServer,
                        type: 'ecore.EClass',
                        key: 'rt.MLFlowServer',
                    }
                ]
            }
        ]
    }
];

export const recursionItems = <T extends Item[]>() => {
    let allItems: Item[] = [];

    items.forEach((value: Item) => {
        allItems.push({
            label: value.label,
            leftIcon: value.leftIcon,
            key: value.label
        })
        value.subMenu !== undefined && value.subMenu.forEach((value_: Item) => {
            allItems.push({
                label: value_.label,
                leftIcon: value_.leftIcon,
                key: value.label
            })
            value_.subMenu !== undefined && value_.subMenu.forEach((value__: Item) => {
                allItems.push({
                    label: value__.label,
                    leftIcon: value__.leftIcon,
                    key: value__.label
                })

                value__.subMenu !== undefined && value__.subMenu.forEach((value___: Item) => {
                    allItems.push({
                        label: value___.label,
                        leftIcon: value___.leftIcon,
                        key: value___.label
                    })
                })
            })

        })
    })

    return allItems;
};

export const renderIcon = <T extends React.ReactNode>(
    item: Item,
    size?: IconPropSize,
    className?: string
) => {
    if (item !== undefined) {
        const Icon = item.leftIcon;
        return <Icon size={size || "s"} className={className || undefined}/>;
    }
    else return undefined;
};

export const splitName = <T extends string>(
    name: string,
) => {
    if (name.includes(".")) {return name.split(".")[1].toLowerCase();}
    else {return name;}

};

export const getItemsMenu = <T extends string>(
    name: string
) => {
    let items_ = items[0].subMenu !== undefined ? items[0].subMenu.find((m) => m.key === name) : undefined;

    if (items_ === undefined) {
        items_ = items[0].subMenu !== undefined ? items[0].subMenu.find((s: any) => s.subMenu.find((f: any) => f.key === name)) : undefined;
    }
    return items_;
};

export const getSubItemsMenu = <T extends string>(
    name: string
) => {
    let items_ = getItemsMenu(name);

    const subItems: Item[] = items_ !== undefined && items_.subMenu !== undefined ? items_.subMenu : [];
    return subItems;
};

export const ButtonTooltip = withTooltip({ direction: 'downRight' })(Button);
